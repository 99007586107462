import React from 'react';
import styles from './styles.module.scss';
import PageWrapper from '../../Layout/Wrapper/PageWrapper';
import Title, { TitleSize } from '../../UI/Title';
import {
	useTariffsFeaturesQuery,
	useTariffsQuery,
} from '../../../api/tariffs/tariffs';
import { MEDIA_URL } from '../../../config/constants';

const Eight = () => {
	const { data: tariffs } = useTariffsQuery();
	const { data: tariffsFeatures } = useTariffsFeaturesQuery();

	return (
		<section className={styles.eightSection}>
			<PageWrapper className={styles.eight}>
				<div className={styles.headerBlock}>
					<Title size={TitleSize.MEDIUM} className={styles.title}>
						<h1>
							<b>Специальные условия для постоянных клиентов</b>
						</h1>
					</Title>
					<p className={styles.subtitle}>
						Заключаем договоры с Организациями и Физическими лицами на полный
						Аутсорсинг объекта. При заключении договора с нашей компанией Вы
						получаете ряд значительных преимуществ для постоянных клиентов
					</p>
				</div>
				<div className={styles.achievements}>
					{tariffsFeatures?.map((feature: any) => (
						<div key={feature.id} className={styles.achievItem}>
							<img src={`${MEDIA_URL}${feature.image}`} alt="" />
							<span dangerouslySetInnerHTML={{ __html: feature.title }} />
						</div>
					))}
				</div>
				<div className={styles.tariffs}>
					{tariffs?.map((tariff: any) => (
						<div key={tariff.id} className={styles.tariffCart}>
							<div className={styles.header}>
								<span className={styles.title}>{tariff.name}</span>
								<div className={styles.discount}>
									<span className={styles.text}>Скидка</span>
									<br />
									<span className={styles.sale}>{tariff.discount}%</span>
								</div>
							</div>
							<div className={styles.peculiarities}>
								{tariff.propertyValues.map((property: any, i: number) => (
									<div key={property.id} className={styles.propertyItem}>
										{i < 3 ? (
											<div className={styles.checkboxWrapper}>
												<input
													readOnly
													type="checkbox"
													className={`${styles.checkbox} ${
														!+property.value && styles.checkboxDisabled
													}`}
													checked={true}
												/>
											</div>
										) : (
											<div className={styles.circle} />
										)}
										<div className={styles.valueItem}>
											<span>{property.text}</span>
											{property?.value?.length > 1 && (
												<span>{property.value}</span>
											)}
										</div>
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			</PageWrapper>
		</section>
	);
};

export default Eight;
