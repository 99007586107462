import './styles/reset.scss';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { router } from './routes';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAppSelector } from './store/hook';

function App() {

	return (
		<div className="App">
			<HelmetProvider>
				<Helmet>
					
				</Helmet>
				<ThemeProvider theme={theme}>
					<Provider store={store}>
						<RouterProvider router={router} />
					</Provider>
				</ThemeProvider>
			</HelmetProvider>
		</div>
	);
}

export default App;
