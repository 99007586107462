import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Layout/Footer/Footer';
import Header from '../components/Layout/Header/Header';
import { useAppDispatch } from '../store/hook';
import { useRepairBlocksQuery, useServicesQuery } from '../api/content/Content';
import { setServices } from '../store/slices/servicesSlice';
import useResizeChecker from '../hooks/useResizeChecker';
import { setRepairs } from '../store/slices/repairsBlockSlice';
import { Helmet } from 'react-helmet-async';
import InnerHTML from 'dangerously-set-html-content'
import { useOptions } from '../hooks/useOptions'
import { Parser } from '../helpers/htmlParser';

const RootLayout: FC = () => {
	const dispatch = useAppDispatch();
	const { isError: isErrorServices, isLoading: isLoadingServices, data: servicesData } = useServicesQuery();
	const { isError: isErrorRepairs, isLoading: isLoadingRepairs, data: repairsBlocks } = useRepairBlocksQuery();

	useEffect(() => {
		if (!isErrorRepairs && !isLoadingRepairs && repairsBlocks) {
			dispatch(setRepairs(repairsBlocks));
		}
	}, [isErrorRepairs, isLoadingRepairs, repairsBlocks]);

	useResizeChecker();

	useEffect(() => {
		if (!isErrorServices && !isLoadingServices && servicesData) {
			dispatch(setServices(servicesData));
		}
	}, [isErrorServices, isLoadingServices, servicesData]);

	const {headMetrics, bodyMetrics, isSuccess} = useOptions()

	Parser.htmlParser(headMetrics)

	return (
		<>
			<Helmet>

			</Helmet>
			<div className="rootLayout">
				<Header />
				<main>
					<Outlet />
				</main>
				<Footer />
			</div>
			{
				bodyMetrics && <InnerHTML html={bodyMetrics}/>
			}
		</>
	);
};

export default RootLayout;

