import React from 'react';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Modal as MaterialModal } from '@mui/material';
import Close from '@mui/icons-material/Close';

import styles from './styles.module.scss';

interface ModalProps {
	children: React.ReactNode;
	isOpen: boolean;
	className?: string;
	classNameContent?: string;
	isShowIcon?: boolean;
	padding?: string;

	onClose(): void;
}

export const Modal: React.FC<ModalProps> = ({
	children,
	isOpen,
	className,
	classNameContent,
	isShowIcon = true,
	onClose,
	padding = '46px 42px',
}) => (
	<MaterialModal className={className} open={isOpen} onClose={onClose}>
		<Box
			position="relative"
			bgcolor="white"
			borderRadius="20px"
			className={`${styles.modal} ${classNameContent}`}
			padding={padding}
		>
			{isShowIcon && (
				<IconButton
					onClick={onClose}
					className={styles.modalCloseIcon}
					sx={{
						position: 'absolute',
						top: '-5px',
						right: '-50px',
					}}
				>
					<Close />
				</IconButton>
			)}
			{children}
		</Box>
	</MaterialModal>
);
