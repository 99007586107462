import { DetailAddressType } from '../modals/CallMasterModal/CallMasterForm';

export const formattedDetailAddressHandler = (
	addressComponents: Array<{ kind: string; name: string }>
) => {
	const result: DetailAddressType = {} as DetailAddressType;
	addressComponents?.forEach(
		(addressComponent) =>
			(result[addressComponent.kind] = addressComponent.name)
	);
	return result;
};
