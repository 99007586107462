import { FC } from 'react';
import Circle from './Circle';
import styles from './Subtitle.module.scss';
import { useAppSelector } from '../../../../store/hook';
import { selectCities } from '../../../../store/slices/citySlice';
import { selectServices } from '../../../../store/slices/servicesSlice';

const Subtitle: FC = () => {
	const currentCity = useAppSelector(selectCities).selectedCity;
	const activeService = useAppSelector(selectServices).activeService;

	return (
		<div className={styles.subtitle}>
			<Circle />
			<Circle />
			<Circle />
			<div className={styles.text}>
				по фиксированной стоимости
				{currentCity && (
					// @ts-ignore
					<span className={styles.price}>
						{' '}
						от {(activeService?.prices?.min as any) || currentCity.price}₽
					</span>
				)}
			</div>
		</div>
	);
};

export default Subtitle;
