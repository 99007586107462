import { baseApi } from '../index';
import { ISettingsResponse } from '../../types/api/settings';

export const staticApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		settings: builder.query<any, void>({
			query: () => '/settings/all',
			transformResponse: (res: ISettingsResponse) => res.data,
			keepUnusedDataFor: 360,
		}),
	}),
});

export const { useSettingsQuery } = staticApi;
