import { FC, useCallback, useEffect, useRef, useState } from 'react';
import NavList from './NavList/NavList';
import NavPhones from './NavPhones/NavPhones';
import Hamburger from 'hamburger-react';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { selectDevice } from '../../../../store/slices/deviceSlice';
import styles from './Navbar.module.scss';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';
import { setIsHeaderOpen } from '../../../../store/slices/headerOpenSlice';

const Navbar: FC = () => {
	const dispatch = useAppDispatch();

	const [isOpen, setIsOpen] = useState(false);
	const device = useAppSelector(selectDevice);
	const modal = useRef<HTMLDivElement | null>(null);
	const burger = useRef<HTMLDivElement | null>(null);

	const handler = useCallback((event: MouseEvent | TouchEvent) => {
		if (burger.current && burger.current.contains(event.target as Node)) {
			return;
		}
		setIsOpen(false);
	}, []);

	useOnClickOutside(modal, handler);

	useEffect(() => {
		dispatch(setIsHeaderOpen(isOpen));
		isOpen
			? document.body.classList.add('overflowedBody')
			: document.body.classList.remove('overflowedBody');
	}, [isOpen]);

	return (
		<>
			<nav className={styles.nav}>
				<NavList showLinks={device.isDesktop} />
				<div className={styles.phonesHamburger}>
					<NavPhones isShowPhone={device.isDesktop} />
					{!device.isDesktop && (
						<div className={styles.phonesHamburgerContent} ref={burger}>
							<Hamburger toggled={isOpen} toggle={setIsOpen} />
						</div>
					)}
				</div>
			</nav>
			<BurgerMenu isOpen={isOpen} ref={modal}>
				<div className={styles.modal}>
					<NavList
						showLinks={!device.isDesktop}
						setIsOpen={setIsOpen}
						showLogo={false}
					/>
					<NavPhones inModal={true} />
				</div>
			</BurgerMenu>
		</>
	);
};

export default Navbar;
