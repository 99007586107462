import dayjs from 'dayjs';

export const TIME_FROM = {
	min: dayjs().set('hour', 8).set('minute', 59),
	max: dayjs().set('hour', 21),
};

export const TIME_TO = {
	min: dayjs().set('hour', 9),
	max: dayjs().set('hour', 21),
};
