import { FC, useEffect, useState } from 'react';
import Tag from './index';
import styles from './Tag.module.scss';
import { IService } from '../../../../types/api/content';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { CustomSelect } from '../../../CustomSelect/CustomSelect';
import { MenuItem } from '@mui/material';
import { setActiveService } from '../../../../store/slices/servicesSlice';
import { useAppDispatch } from '../../../../store/hook';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';

interface Props {
	services: IService[];
	isBottom?: boolean;
	executeScroll: () => void
}

const Tags: FC<Props> = ({ services, isBottom = false, executeScroll }) => {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const { width } = useWindowSize();

	const [selectedServiceId, setSelectedServiceId] = useState<string>();
	const [isAreaOpen, setIsAreaOpen] = useState<boolean>(false);

	const cityFromLs = localStorage.getItem('currentCity');
	const cityFromLsParsed =
		cityFromLs && cityFromLs !== 'undefined' && JSON.parse(cityFromLs);

	useEffect(() => {
		const activeServiceById =
			services?.find((service) => `/${service.slug}` === pathname) ||
			services[0];
		activeServiceById && dispatch(setActiveService(activeServiceById));
		setSelectedServiceId(activeServiceById?.id?.toString());
	}, [services, pathname]);

	const handleNavigate = (service: IService) => {
		navigate(`/${service.slug}?utm_city=${cityFromLsParsed?.slug}`);
		isBottom && executeScroll()
	};

	return (
		<div className={styles.tags}>
			{width > 700 && services.length ? (
				services.map((service) => <Tag isBottom={isBottom} executeSroll={executeScroll} key={service.id} service={service} />)
			) : (
				<CustomSelect
					key={selectedServiceId}
					value={selectedServiceId}
					onChange={setSelectedServiceId}
					className={`${
						isAreaOpen
							? styles.selectServiceWithRotatedArrow
							: styles.selectServices
					}`}
					onOpen={() => setIsAreaOpen(true)}
					onClose={() => setIsAreaOpen(false)}
				>
					{services.map((service) => {
						return (
							<MenuItem
								key={service.id}
								value={service.id.toString()}
								onClick={() => handleNavigate(service)}
							>
								<div
									className={cn(
										styles.selectItem,
										selectedServiceId === service.id.toString() && styles.active
									)}
								>
									{service.title}
								</div>
							</MenuItem>
						);
					})}
				</CustomSelect>
			)}
		</div>
	);
};

export default Tags;
