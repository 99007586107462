import React, { FC, ReactNode } from 'react';
import styles from './PageWrapper.module.scss';

interface Props {
	children: ReactNode;
	className?: string;
}

const PageWrapper: FC<Props> = ({ children, className }) => {
	return <div className={`${styles.pageWrapper} ${className}`}>{children}</div>;
};

export default PageWrapper;
