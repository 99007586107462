import React, { FC, useEffect, useState } from 'react';
import styles from './Footer.module.scss';
import { NavLink } from 'react-router-dom';
import PageWrapper from '../Wrapper/PageWrapper';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { setSettings } from '../../../store/slices/settingsSlice';
import { Button } from '../../UI/Button/Button';
import { ButtonSize } from '../../UI/Button/types';
import { useSettingsQuery } from '../../../api/static/staticApi';
import { HashLink as Link } from 'react-router-hash-link';
import { setServicesBlockFlag } from '../../../store/slices/servicesSlice';
import { CallMasterRefactoringModal } from '../../../modals/CallMasterRefactoring/CallMasterRefactoringModal';

const Footer: FC = () => {
	const dispatch = useAppDispatch();

	const { isError, isLoading, data: settings } = useSettingsQuery();
	useEffect(() => {
		dispatch(setSettings(settings));
	}, [isError, isLoading, settings]);

	// const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

	const [isOpenRefactoredModal, setIsOpenRefactoredModal] =
		useState<boolean>(false);

	const year = new Date().getFullYear();

	const services = useAppSelector((state) => state.services.items);
	const repairs = useAppSelector((state) => state.repairs.repairs);
	const phone = settings && settings['contacts.item.phone'];
	const whatsapp = settings && settings['contacts.item.whatsapp'];
	const telegram = settings && settings['contacts.item.telegram'];
	const email = settings && settings['contacts.item.email'];
	const workHours = settings && settings['footer.item.work.hours'];
	const address = settings && settings['contacts.item.address'];

	return (
		<footer className={styles.footer} id="footer">
			{/*<CallMasterModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} />*/}
			<CallMasterRefactoringModal
				isOpen={isOpenRefactoredModal}
				setIsOpen={setIsOpenRefactoredModal}
			/>
			<PageWrapper>
				<div className={styles.footerContent}>
					<div className={styles.first}>
						<div className={styles.logo}>
							<NavLink
								to="/"
								onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
							>
								<img src="/logo.svg" alt="logo" />
							</NavLink>
						</div>
						<p className={styles.description}>
							Устанавливаем, обслуживаем и ремонтируем кондиционеры более 20 лет
						</p>
					</div>
					<div className={styles.second}>
						<Link smooth to="/#service">
							<h3 className={styles.columnTitle}>Услуги</h3>
						</Link>
						<ul className={styles.footerList}>
							{services.map((service) => (
								<Link
									key={service.id}
									to={`/${service.slug}`}
									onClick={() => dispatch(setServicesBlockFlag())}
								>
									<li>{service.name}</li>
								</Link>
							))}
						</ul>
					</div>
					<div className={styles.third}>
						<Link smooth to="/#repair">
							<h3 className={styles.columnTitle}>Неисправности</h3>
						</Link>
						<ul className={styles.footerList}>
							{repairs.map((repair: any) => (
								<Link key={repair.id} smooth to="/#repair">
									<li>{repair.header}</li>
								</Link>
							))}
						</ul>
					</div>
					<div className={styles.fourth}>
						<h3 className={styles.columnTitle}>Контакты</h3>
						<div className={styles.contacts}>
							<div className={styles.phone}>
								{phone?.value && (
									<>
										<img src="/phone-footer-icon.svg" alt="" />
										<a
											className={styles.phoneLink}
											href={`tel:${phone?.value}`}
										>
											{phone?.value}
										</a>
									</>
								)}
								{whatsapp?.value && (
									<a
										href={`https://wa.me/${whatsapp?.value}`}
										target="_blank"
										rel="noreferrer"
									>
										<img src="/whatsapp-footer-icon.svg" alt="" />
									</a>
								)}
								{telegram?.value && (
									<a
										href={`https://t.me/${telegram?.value}`}
										target="_blank"
										rel="noreferrer"
									>
										<img src="/telegram-footer-icon.svg" alt="" />
									</a>
								)}
							</div>
							{email?.value && (
								<div className={styles.email}>
									<img src="/email-icon.svg" alt="" />
									<a
										className={styles.phoneLink}
										href={`mailto:${email?.value}`}
									>
										{email?.value}
									</a>
								</div>
							)}
							{/*<Button*/}
							{/*	className={styles.callMasterButton}*/}
							{/*	size={ButtonSize.LARGE}*/}
							{/*	onClick={() => setIsOpenModal(true)}*/}
							{/*>*/}
							{/*	Вызвать мастера*/}
							{/*</Button>*/}
							<Button
								className={styles.callMasterButton}
								size={ButtonSize.LARGE}
								onClick={() => setIsOpenRefactoredModal(true)}
							>
								Вызвать мастера
							</Button>
							{workHours?.value && (
								<div className={styles.workHours}>
									<img src="/time-footer-icon.svg" alt="" />
									<span className={styles.workHoursValue}>
										{workHours?.value}
									</span>
								</div>
							)}
							{address?.value && (
								<div className={styles.addressBlock}>
									<img src="/geo-icon.svg" alt="" />
									<span className={styles.address}>{address?.value}</span>
								</div>
							)}
						</div>
					</div>
				</div>
			</PageWrapper>
			<div className={styles.annotation}>
				<PageWrapper className={styles.annotationWrapper}>
					<div>©Мастер кондиционеров 2003-{year}</div>
					<NavLink to="/privacy-policy">Политика конфиденциальности</NavLink>
					{/*<NavLink to='/site-rules'>Правила сайта</NavLink>*/}
					<NavLink
						className={styles.option}
						to="https://palax.info"
						target="_blank"
						title="Создание, продвижение, обслуживание сайтов"
					>
						Сайт разработан PALAX
					</NavLink>
				</PageWrapper>
			</div>
		</footer>
	);
};

export default Footer;
