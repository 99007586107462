import {
	configureStore,
	ThunkAction,
	Action,
	combineReducers,
} from '@reduxjs/toolkit';
import { baseApi } from '../api';
import servicesSlice from './slices/servicesSlice';
import citySlice from './slices/citySlice';
import deviceSlice from './slices/deviceSlice';
import repairsBlockSlice from './slices/repairsBlockSlice';
import settingsSlice from './slices/settingsSlice';
import mastersSlice from './slices/mastersSlice';
import successModalSlice from './slices/successModalSlice';
import headerOpenSlice from './slices/headerOpenSlice';

const rootReducer = combineReducers({
	[baseApi.reducerPath]: baseApi.reducer,
	services: servicesSlice.reducer,
	settings: settingsSlice.reducer,
	cities: citySlice.reducer,
	device: deviceSlice.reducer,
	repairs: repairsBlockSlice.reducer,
	masters: mastersSlice.reducer,
	successModal: successModalSlice.reducer,
	headerState: headerOpenSlice.reducer,
});

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(baseApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
