import { createSlice } from '@reduxjs/toolkit';

export interface mastersState {
	isOpenSuccessModal: boolean;
	response: any;
}

const initialState: mastersState = {
	isOpenSuccessModal: false,
	response: {},
};

const successModalSlice = createSlice({
	name: 'successModalSlice',
	initialState,
	reducers: {
		setIsOpenSuccessModal(state, action) {
			state.isOpenSuccessModal = action.payload;
		},
		setResponse(state, action) {
			state.response = action.payload;
		},
	},
});

export const { setIsOpenSuccessModal, setResponse } = successModalSlice.actions;
export default successModalSlice;
