import React, {Dispatch, FC, memo, SetStateAction, useEffect, useId, useState,} from 'react';
import styles from './styles.module.scss';
import {StyledTextField} from '../styledInputs';

import dayjs, {Dayjs} from 'dayjs';

import {useCallMasterMutation} from '../../api/form/form';
import {useAppDispatch, useAppSelector} from '../../store/hook';
import {zodResolver} from '@hookform/resolvers/zod';

import {selectCities} from '../../store/slices/citySlice';
import {selectServices} from '../../store/slices/servicesSlice';
import {IRepair} from '../../types/api/content';
import {FormProvider, useForm} from 'react-hook-form';
import {YMapsAddress} from './components/YMapsAddress/YMapsAddress';
import {PlanTime} from './components/PlanTime/PlanTime';
import {MyStyledTimePicker} from './components/MyStyledTimePicker/MyStyledTimePicker';
import {
    CallMasterRefactoringControllers
} from './components/CallMasterRefactoringControllers/CallMasterRefactoringControllers';
import {TIME_FROM, TIME_TO} from './consts/dateConsts';
import {MyInputMask} from './components/MyInputMask/MyInputMask';
import {getTimes} from './helpers/getTimes';
import {setIsOpenSuccessModal, setResponse,} from '../../store/slices/successModalSlice';
import {CallMasterRefactoringFormFileds, formSchema,} from './consts/formSchema';

export type DetailAddressType = {
    province: string;
    area: string;
    locality: string;
    street: string;
    house: string;
    flat: string;
    lat: number;
    lon: number;
};

type CallMasterFormPropsType = {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    repairVariant?: IRepair;
};

export const TODAY_CONST = new Date();
export const TOMORROW_CONST = new Date(TODAY_CONST);
TOMORROW_CONST.setDate(TOMORROW_CONST.getDate() + 1);

const CallMasterRefactoring: FC<CallMasterFormPropsType> = ({
                                                                setIsOpen,
                                                                repairVariant,
                                                            }) => {
    const dispatch = useAppDispatch();
    const mockId = useId();

    const currentCity = useAppSelector(selectCities).selectedCity;
    const activeService = useAppSelector(selectServices).activeService;

    const [updateForm] = useCallMasterMutation();

    const [callDate, setCallDate] = useState<any>('today');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [timeFrom, setTimeFrom] = useState<Dayjs | null>(
        dayjs('2022-04-17T9:00:59')
    );
    const [timeTo, setTimeTo] = useState<Dayjs | null>(dayjs('2022-04-17T21:00'));

    const formMethods = useForm<CallMasterRefactoringFormFileds>({
        resolver: zodResolver(formSchema),
    });

    const {register, handleSubmit} = formMethods;

    const callDateHandler = () => {
        if (callDate === 'today') {
            setStartDate(TODAY_CONST);
            return TODAY_CONST.toLocaleDateString();
        } else if (callDate === 'tomorrow') {
            setStartDate(TOMORROW_CONST);
            return TOMORROW_CONST.toLocaleDateString();
        } else {
            return startDate?.toLocaleDateString();
        }
    };

    const onSubmit = async (data: CallMasterRefactoringFormFileds) => {
        const {fromHours, fromMinutes, toHours, toMinutes} = getTimes(
            timeFrom,
            timeTo
        ); //если бы дата была с логикой setValue - можно было бы в контролере(редаксе) мутировать данные
        const isRepairData = repairVariant
            ? Object.values(repairVariant)?.length
            : null;

        const filteredData = {
            ...data,
            addressFields: {...data.addressFields, flat: data.flat},
            flat: undefined,
            city: currentCity?.name,
            service: activeService?.title,
            timeFrom: `${fromHours}:${fromMinutes}`,
            timeTo: `${toHours}:${toMinutes}`,
            repairName: !!isRepairData
                ? `${repairVariant.header}, Ремонт от ${repairVariant.min_price} руб.`
                : null,
            date: callDateHandler(),
        };
        const result = await updateForm(filteredData);
        dispatch(setIsOpenSuccessModal(true));
        dispatch(setResponse(result));
        setIsOpen(false);
    };

    useEffect(() => {
        callDateHandler();
        // setStartDate()
    }, [callDate, startDate]);

    return (
        <FormProvider {...formMethods}>
        <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
            <YMapsAddress id={mockId}/>
            <h3 className={styles.title}>Вызвать мастера</h3>
            <div className={styles.form}>
                {<StyledTextField
                    label="Имя"
                    className={styles.input}
                    {...register('name')}
                    required
                />}
                <MyInputMask name="phone" label="Телефон"/>
                <StyledTextField
                    {...register('address')}
                    className={styles.addressInput}
                    required
                    label="Адрес"
                    id={mockId}
                />
                <StyledTextField
                    {...register('flat')}
                    className={styles.addressInput}
                    label="Номер квартиры/офиса/помещения/или как вас найти"
                />
            </div>
            <PlanTime
                startDate={startDate}
                setStartDate={setStartDate}
                setCallDateProp={setCallDate}
            />
            <div className={styles.favoriteTime}>
                <h3 className={styles.title}>Предпочтительное время работ:</h3>
                <div className={styles.inputsBlock}>
                    <MyStyledTimePicker
                        className={styles.picker}
                        label="Время с:"
                        minTime={TIME_FROM.min}
                        maxTime={TIME_FROM.max}
                        ampm={false}
                        value={timeFrom}
                        onChange={(newValue: any) => setTimeFrom(newValue)}
                    />
                    <MyStyledTimePicker
                        className={styles.picker}
                        label="Время по:"
                        minTime={TIME_TO.min}
                        maxTime={TIME_TO.max}
                        ampm={false}
                        value={timeTo}
                        onChange={(newValue: any) => setTimeTo(newValue)}
                    />
                </div>
            </div>
            <CallMasterRefactoringControllers/>
        </form>
        </FormProvider>
    );
};

export default memo(CallMasterRefactoring);

{
    /* даты тоже можно в компонент, но надо переделать логику даты на setValue
                    из rhf */
}
