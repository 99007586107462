import { FC } from 'react';
import styles from './CallMasterRefactoringControllers.module.scss';
import { Button } from '../../../../components/UI/Button/Button';
import { ButtonSize } from '../../../../components/UI/Button/types';
import { NavLink } from 'react-router-dom';

export const CallMasterRefactoringControllers: FC = () => {
	return (
		<div className={styles.buttonWrapper}>
			<Button size={ButtonSize.LARGE} type="submit">
				Вызвать мастера
			</Button>

			<p className={styles.annotation}>
				Нажимая на кнопку отправки, я даю согласие на обработку персональных
				данных и соглашаюсь с условиями{' '}
				<NavLink to={'/privacy-policy'}>Политики конфиденциальности</NavLink>
			</p>
		</div>
	);
};
