import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ICity } from '../../types/api/cities';

export interface ICityState {
	selectedCity: ICity | null;
	cities: Array<ICity> | null;
}

const cityFromLs = localStorage.getItem('currentCity');
const cityFromLsParsed =
	cityFromLs && cityFromLs !== 'undefined' && JSON.parse(cityFromLs);

const initialState: ICityState = {
	selectedCity: cityFromLsParsed ? cityFromLsParsed : null,
	cities: [],
};

const citiesSlice = createSlice({
	name: 'cities',
	initialState,
	reducers: {
		setCity(state, action: PayloadAction<ICity>) {
			state.selectedCity = action.payload;
		},
		setCities(state, action: PayloadAction<Array<ICity>>) {
			state.cities = action.payload;
		},
	},
});

export const { setCity, setCities } = citiesSlice.actions;
export default citiesSlice;

export const selectCities = (state: RootState) => state.cities;
