import { baseApi } from '../index';

export const tariffsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		tariffsFeatures: builder.query<any, void>({
			query: () => '/content/tariffs/features',
			transformResponse: (res: any) => res.data,
			keepUnusedDataFor: 360,
		}),
		tariffs: builder.query<any, void>({
			query: () => '/content/tariffs/all',
			transformResponse: (res: any) => res.data,
			keepUnusedDataFor: 360,
		}),
	}),
});

export const { useTariffsFeaturesQuery, useTariffsQuery } = tariffsApi;
