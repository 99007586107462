import React, { FC } from 'react';
import Left from './Left';
import Right from './Right';
import styles from './Fifth.module.scss';
import PageWrapper from '../../Layout/Wrapper/PageWrapper';

const Fifth: FC = () => {
	return (
		<section className={styles.fifthSection} id="about">
			<PageWrapper className={styles.fifth}>
				<Left />
				<Right />
			</PageWrapper>
		</section>
	);
};

export default Fifth;
