import { ZodType, z } from 'zod';

export type DataTypesFromZod<formSchema extends ZodType<any, any, any>> =
	z.infer<formSchema>;

export const formSchema = z.object({
	address: z.string().optional(),
	addressFields: z
		.object({
			area: z.string().optional(),
			country: z.string().optional(),
			flat: z.string().optional(),
			lat: z.number().optional(),
			locality: z.string().optional(),
			lon: z.number().optional(),
			province: z.string().optional(),
			street: z.string().optional(),
		})
		.optional(),
	city: z.string().optional(),
	date: z.string().optional(),
	name: z.string().optional(),
	phone: z.string().optional(),
	repairName: z.null().optional(),
	timeFrom: z.string().optional(),
	flat: z.string().optional(),
	timeTo: z.string().optional(),
});

export type CallMasterRefactoringFormFileds = DataTypesFromZod<
	typeof formSchema
>;
