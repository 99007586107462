import { FC, useState } from 'react';
import Aspect from './Aspect';
import styles from './Right.module.scss';
import { Button } from '../../../UI/Button/Button';
import { ButtonSize } from '../../../UI/Button/types';
import { CallMasterModal } from '../../../../modals/CallMasterModal/CallMasterModal';

const pluses = [
	{
		id: 1,
		title: 'Качество работы',
		description:
			'Мы экономим Ваши деньги, за нами не надо переделывать! Вы можете быть уверены в 100% качестве работ',
	},
	{
		id: 2,
		title: 'Стоимость не изменится',
		description:
			'Стоимость обслуживания является фиксированной и не изменится по прибытию  специалиста на объект',
	},
	{
		id: 3,
		title: 'Оригинальные запчасти',
		description:
			'Используем только запчасти, рекомендуемые заводом-производителем',
	},
	{
		id: 4,
		title: 'Гарантия',
		description:
			'Вы получаете гарантийный талон, подтверждающий наши обязательства и качество наших работ',
	},
	{
		id: 5,
		title: 'Полный комплекс услуг',
		description:
			'Расчет необходимой мощности, разработка проекта установки, подбор и доставка техники, монтаж оборудования и его обслуживание',
	},
	{
		id: 6,
		title: 'Выгодная цена',
		description:
			'За счет грамотной автоматизации бизнес-процессов, оптимизации издержек, продуманной логистики и прямые контакты с ведущими брендами',
	},
];
const Right: FC = () => {
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	return (
		<div className={styles.right}>
			<CallMasterModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} />{' '}
			<div className={styles.aspects}>
				{pluses.map((plus) => (
					<Aspect plus={plus} key={plus.id} image={`/aspects/${plus.id}.webp`} />
				))}
			</div>
			<Button
				className={styles.callMasterButton}
				size={ButtonSize.LARGE}
				onClick={() => setIsOpenModal(true)}
			>
				Вызвать мастера
			</Button>
			<div className={styles.imageManWrapper}>
				<img
					src="/cropped.webp"
					className={styles.mobileImageBackground}
					alt=""
				/>
				<img className={styles.mobileImage} src="/vkl-conditioner.png" alt="" />
			</div>
		</div>
	);
};

export default Right;
