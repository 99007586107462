import React from 'react';
import PageWrapper from '../../components/Layout/Wrapper/PageWrapper';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export const ErrorPage = () => {
	return (
		<section className={styles.errorPageSection}>
			<PageWrapper>
				<h1 className={styles.title}>404: Страница не найдена</h1>
				<Link to="/">Вернуться на главную</Link>
			</PageWrapper>
		</section>
	);
};
