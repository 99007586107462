import React, { useState } from 'react';
import PageWrapper from '../../Layout/Wrapper/PageWrapper';
import styles from './styles.module.scss';
import Title, { TitleSize } from '../../UI/Title';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './swiper.styles.scss';
import { useWorkResultsQuery } from '../../../api/content/Content';
import { MEDIA_URL } from '../../../config/constants';
import { ArrowSwiper } from '../../../ui/svgAsReactComponents/ArrowSwiper';

const Seventh = () => {
	const { data: workResults } = useWorkResultsQuery();

	const [swiper, setSwiper] = useState<SwiperCore>();

	const handleNext = () => {
		if (swiper) {
			swiper.slideNext();
		}
	};
	const handlePrev = () => {
		if (swiper) {
			swiper.slidePrev();
		}
	};

	const delimiterIconStyles = {
		width: '50px',
		height: '50px',
		backgroundSize: 'cover',
		borderRadius: 'none',
		backgroundImage: `url('/delimiter-icon.svg')`,
	};

	const pagination = {
		clickable: true,
		el: '.swiper-pagination',
		renderBullet: function () {
			return '<span class="' + 'swiper-pagination-bullet' + '">' + '</span>';
		},
	};

	return (
		<section className={styles.seventhSection}>
			<PageWrapper className={styles.seventh}>
				<Title size={TitleSize.MEDIUM} className={styles.title}>
					<h1>
						<b>Оцените результаты</b>
						<br /> нашей работы
					</h1>
				</Title>
				{workResults?.length && (
					<div className={styles.swiperContainer}>
						<Swiper
							draggable={false}
							allowTouchMove={false}
							slidesPerView={1}
							pagination={pagination}
							spaceBetween={20}
							modules={[Pagination]}
							className={styles.mySwiper}
							onSwiper={setSwiper}
						>
							{workResults?.map((result) => (
								<SwiperSlide key={result.id} className={styles.swiperSlide}>
									<ReactBeforeSliderComponent
										className={styles.beforeAfterSlider}
										firstImage={{
											imageUrl: `${MEDIA_URL}${result.image_before}`,
										}}
										secondImage={{
											imageUrl: `${MEDIA_URL}${result.image_after}`,
										}}
										delimiterIconStyles={delimiterIconStyles}
									/>
								</SwiperSlide>
							))}
							{workResults?.map((result) => (
								<SwiperSlide key={result.id} className={styles.swiperSlide}>
									<ReactBeforeSliderComponent
										className={styles.beforeAfterSlider}
										firstImage={{
											imageUrl: `${MEDIA_URL}${result.image_before}`,
										}}
										secondImage={{
											imageUrl: `${MEDIA_URL}${result.image_after}`,
										}}
										delimiterIconStyles={delimiterIconStyles}
									/>
								</SwiperSlide>
							))}
							<div className={styles.controllersBlock}>
								<div className={styles.buttonPrev} onClick={handlePrev}>
									<ArrowSwiper />
								</div>
								<div className="swiper-pagination"></div>
								<div className={styles.buttonNext} onClick={handleNext}>
									<ArrowSwiper />
								</div>
							</div>
						</Swiper>
					</div>
				)}
			</PageWrapper>
		</section>
	);
};
export default Seventh;
