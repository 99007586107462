import React, {
	Dispatch,
	FC,
	memo,
	SetStateAction,
	useEffect,
	useId,
	useRef,
	useState,
} from 'react';
import styles from './styles.module.scss';
import { StyledTextField, StyledTimePicker } from '../styledInputs';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import dayjs, { Dayjs } from 'dayjs';
import { Button } from '../../components/UI/Button/Button';
import { ButtonSize } from '../../components/UI/Button/types';
import {
	FIELDS_CONST,
	TODAY_CONST,
	TOMORROW_CONST,
} from '../ApplicationToMasterModal/ApplicationToMasterModal';
import { NavLink } from 'react-router-dom';
import { useCallMasterMutation } from '../../api/form/form';
import ymaps from 'yandex-maps';
import { formattedDetailAddressHandler } from '../../helpers/formattedDetailAddressHandler';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import {
	setIsOpenSuccessModal,
	setResponse,
} from '../../store/slices/successModalSlice';
import { selectCities } from '../../store/slices/citySlice';
import { selectServices } from '../../store/slices/servicesSlice';
import { IRepair } from '../../types/api/content';
import { YMaps, Map } from '@pbe/react-yandex-maps';

export type DetailAddressType = {
	province: string;
	area: string;
	locality: string;
	street: string;
	house: string;
	flat: string;
	lat: number;
	lon: number;
};

type CallMasterFormPropsType = {
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	repairVariant?: IRepair;
};

const behaviors = [''];
const mapOptions = { minZoom: 8, maxZoom: 20 };
const defaultState = {
	center: [55.751574, 37.573856],
	zoom: 11,
	controls: [],
};
const CallMasterForm: FC<CallMasterFormPropsType> = ({
	setIsOpen,
	repairVariant,
}) => {
	const dispatch = useAppDispatch();
	const mockId = useId();
	const map = useRef<ymaps.Map>();

	const currentCity = useAppSelector(selectCities).selectedCity;
	const activeService = useAppSelector(selectServices).activeService;

	const [updateForm, { isError, error }] = useCallMasterMutation();

	const [errorsState, setErrorsState] = useState<Array<any>>([]);

	const [isMount, setIsMount] = useState<boolean>(false);

	const [name, setName] = useState<string>('');
	const [phone, setPhone] = useState<string>('');
	const phoneValueOnlyNumbers = phone.replace(/\D/g, '');
	const [address, setAddress] = useState<string>('');
	const [apartment, setApartment] = useState<string>('');
	const [callDate, setCallDate] = useState<any>('today');
	const [startDate, setStartDate] = useState<any | null>(null);
	const [timeFrom, setTimeFrom] = useState<Dayjs | null>(
		dayjs('2022-04-17T9:00:59')
	);
	const [timeTo, setTimeTo] = useState<Dayjs | null>(dayjs('2022-04-17T21:00'));
	const [detailAddressComponents, setDetailAddressComponents] =
		useState<DetailAddressType>({} as DetailAddressType);

	const fromHours =
		timeFrom && timeFrom?.hour() >= 10
			? timeFrom?.hour()
			: `0${timeFrom?.hour()}`;
	const fromMinutes =
		timeFrom && timeFrom?.minute() >= 10
			? timeFrom?.minute()
			: `0${timeFrom?.minute()}`;
	const toHours =
		timeTo && timeTo?.hour() >= 10 ? timeTo?.hour() : `0${timeTo?.hour()}`;
	const toMinutes =
		timeTo && timeTo?.minute() >= 10
			? timeTo?.minute()
			: `0${timeTo?.minute()}`;
	const callDateHandler = () => {
		if (callDate === 'today') {
			setStartDate(TODAY_CONST);
			return TODAY_CONST.toLocaleDateString();
		} else if (callDate === 'tomorrow') {
			setStartDate(TOMORROW_CONST);
			return TOMORROW_CONST.toLocaleDateString();
		} else {
			return startDate?.toLocaleDateString();
		}
	};
	const isRepairData = repairVariant
		? Object.values(repairVariant)?.length
		: null;

	const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const result = await updateForm({
			name,
			address,
			repairName: !!isRepairData
				? `${repairVariant.header}, Ремонт от ${repairVariant.min_price} руб.`
				: null,
			phone: phoneValueOnlyNumbers,
			date: callDateHandler(),
			timeFrom: `${fromHours}:${fromMinutes}`,
			timeTo: `${toHours}:${toMinutes}`,
			addressFields: { ...detailAddressComponents, flat: apartment },
			city: currentCity?.name,
			service: activeService?.title,
		});
		dispatch(setIsOpenSuccessModal(true));
		dispatch(setResponse(result));
		setIsOpen(false);
	};

	const loadSuggest = (ymaps: any) => {
		const suggestView =
			!isMount && new ymaps.SuggestView(mockId, { results: 5, zIndex: 9 });
		setIsMount(true);
		suggestView.events.add('select', (e: any) => {
			const item = e.get('item');
			ymaps.geocode(item.value).then(function (res: any) {
				const geoObj = res.geoObjects.get(0);
				const address =
					geoObj.properties.getAll().metaDataProperty.GeocoderMetaData.Address;
				const addressFieldsObj = formattedDetailAddressHandler(
					address?.Components
				);
				const addressLat = geoObj.geometry._coordinates[0];
				const addressLon = geoObj.geometry._coordinates[1];
				setDetailAddressComponents({
					...addressFieldsObj,
					lon: addressLon,
					lat: addressLat,
				});
				geoObj.properties.balloonContentHeader = address.formatted;
				setAddress(address.formatted);
				const isHouseSelected = address.Components.find(
					(elem: any) => elem.kind === 'house'
				);
				if (isHouseSelected) {
					suggestView.options.set('provider', 'none');
				} else {
					suggestView.options.set('provider', 'yandex#map');
				}
			});
		});
	};

	useEffect(() => {
		// @ts-ignore
		setErrorsState(error?.data?.errors);
	}, [isError]);

	useEffect(() => {
		callDateHandler();
		// setStartDate()
	}, [callDate, startDate]);

	return (
		<form className={styles.wrapper}>
			<YMaps query={{ apikey: 'ba6edea8-8a38-4293-8e38-95083f846eef' }}>
				<Map
					className={styles.myMap}
					// @ts-ignore
					instanceRef={map}
					defaultState={defaultState}
					options={mapOptions}
					behaviors={behaviors}
					onLoad={(ymaps) => loadSuggest(ymaps)}
					modules={['SuggestView', 'geolocation', 'geocode']}
				></Map>
			</YMaps>
			<h3 className={styles.title}>Вызвать мастера</h3>
			<div className={styles.form}>
				<StyledTextField
					label="Имя"
					className={styles.input}
					value={name}
					onChange={(e) => setName(e.currentTarget.value)}
					required
				/>
				{
					// @ts-ignore
					<InputMask
						mask="+7 (999) 999-99-99"
						value={phone}
						onChange={(e) => setPhone(e.currentTarget.value)}
					>
						{/*@ts-ignore*/}
						{(inputProps: any) => (
							// @ts-ignore
							<StyledTextField
								label="Телефон"
								required
								className={styles.input}
								{...inputProps}
								InputLabelProps={{
									style: { color: '#CCC0AC' },
								}}
							/>
						)}
					</InputMask>
				}
				<StyledTextField
					name="address"
					value={address}
					onChange={(e) => setAddress(e.currentTarget.value)}
					className={styles.addressInput}
					required
					label="Адрес"
					id={mockId}
				/>
				<StyledTextField
					name="apartment"
					value={apartment}
					onChange={(e) => setApartment(e.currentTarget.value)}
					className={styles.addressInput}
					label="Номер квартиры/офиса/помещения/или как вас найти"
				/>
			</div>
			<div className={styles.planTime}>
				<div className={styles.todayTomorrow}>
					<div
						className={`${styles.tab}
                        ${callDate === 'today' && styles.tabActive}`}
						onClick={() => setCallDate('today')}
					>
						Сегодня
					</div>
					<div
						className={`${styles.tab}
                        ${callDate === 'tomorrow' && styles.tabActive}`}
						onClick={() => setCallDate('tomorrow')}
					>
						Завтра
					</div>
				</div>
				<div
					className={`${styles.tab} ${styles.datepickerTab}
                    ${callDate === 'choice' && styles.tabActive}
                    ${callDate === 'choice' && styles.datePickTab}`}
				>
					<DatePicker
						onCalendarOpen={() => setCallDate('choice')}
						placeholderText={'Выбрать дату'}
						className={`${styles.datepicker} 
                        ${callDate === 'choice' && styles.activePicker}`}
						selected={startDate}
						locale={ru}
						dateFormat="dd/MM/yyyy"
						// onSelect={handleDateSelect} //when day is clicked
						onChange={(date) => setStartDate(date)}
					/>
				</div>
			</div>
			<div className={styles.favoriteTime}>
				<h3 className={styles.title}>Предпочтительное время работ:</h3>
				<div className={styles.inputsBlock}>
					{
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							//@ts-ignore
							adapterLocale={ru}
							localeText={
								ruRU.components.MuiLocalizationProvider.defaultProps.localeText
							}
						>
							<StyledTimePicker
								className={styles.picker}
								label="Время с:"
								minTime={dayjs().set('hour', 8).set('minute', 59)}
								maxTime={dayjs().set('hour', 21)}
								ampm={false}
								value={timeFrom}
								onChange={(newValue: any) => setTimeFrom(newValue)}
							/>
							<StyledTimePicker
								className={styles.picker}
								label="Время по:"
								minTime={dayjs().set('hour', 9)}
								maxTime={dayjs().set('hour', 21)}
								ampm={false}
								value={timeTo}
								onChange={(newValue: any) => setTimeTo(newValue)}
							/>
						</LocalizationProvider>
					}
				</div>
			</div>
			<div className={styles.buttonWrapper}>
				<Button
					size={ButtonSize.LARGE}
					className={styles.button}
					type="button"
					disabled={
						!name.length || !address.length || phoneValueOnlyNumbers.length < 11
					}
					onClick={submitHandler}
				>
					Вызвать мастера
				</Button>
				{errorsState?.length && (
					<div className={styles.errorsBlock}>
						{errorsState?.map((error, i) => (
							<div key={i}>
								{/*@ts-ignore*/}
								<span>"{FIELDS_CONST[error.attribute]}" - </span>
								<span>{error.message}</span>
							</div>
						))}
					</div>
				)}
				<p className={styles.annotation}>
					Нажимая на кнопку отправки, я даю согласие на обработку персональных
					данных и соглашаюсь с условиями{' '}
					<NavLink to={'/privacy-policy'}>Политики конфиденциальности</NavLink>
				</p>
			</div>
		</form>
	);
};

export default memo(CallMasterForm);
