import Title, { TitleSize } from '../../../UI/Title';
import { FC } from 'react';
import styles from './styles.module.scss';

const Left: FC = () => {
	return (
		<div>
			<Title className={styles.title} size={TitleSize.MEDIUM}>
				<h1>
					<b>Мы специализируемся только на кондиционерах, </b>
					поэтому знаем о них все
				</h1>
			</Title>
			<div className={styles.descriptionBlock}>
				<p>
					Мастер кондиционеров – климатическая компания полного цикла. Мы
					специализируемся на выполнении любых работ, связанных с
					кондиционированием:{' '}
					<b>
						проектирование систем, поставка и монтаж оборудования, ремонт,
						гарантийное обслуживание
					</b>
					– обращаясь к нам, вы снимаете с себя заботы о комфорте в вашем доме
					или на производстве.
				</p>
				<p>
					В каждом конкретном случае подбирается лучшее техническое решение и
					технологии, учитывающие требования клиента, его финансовые возможности
					и индивидуальные особенности объекта.
				</p>
			</div>
		</div>
	);
};

export default Left;
