import { IService } from '../../types/api/content';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface IServicesState {
	items: IService[];
	activeService: IService | null;
	servicesBlockFlag: number;
}

const initialState: IServicesState = {
	items: [],
	activeService: null,
	servicesBlockFlag: 0,
};

const servicesSlice = createSlice({
	name: 'services',
	initialState,
	reducers: {
		setServices(state, action: PayloadAction<IService[]>) {
			state.items = action.payload;
			state.activeService ??= action.payload[0];
		},
		setActiveService(state, action: PayloadAction<IService>) {
			state.activeService = action.payload;
		},
		setServicesBlockFlag(state) {
			state.servicesBlockFlag = state.servicesBlockFlag + 1;
		},
		resetServicesBlockFlag(state) {
			state.servicesBlockFlag = 0;
		},
	},
});

export const {
	setServices,
	setActiveService,
	setServicesBlockFlag,
	resetServicesBlockFlag,
} = servicesSlice.actions;
export default servicesSlice;

export const selectServices = (state: RootState) => state.services;
