import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import ru from 'date-fns/locale/ru';
import {
	TODAY_CONST,
	TOMORROW_CONST,
} from '../../../ApplicationToMasterModal/ApplicationToMasterModal';
import DatePicker from 'react-datepicker';

interface PlanTimeProps {
	startDate: Date | null;
	setStartDate: React.Dispatch<React.SetStateAction<Date>>;
	setCallDateProp: Dispatch<SetStateAction<string>>;
}

export const PlanTime: FC<PlanTimeProps> = ({
	startDate,
	setStartDate,
	setCallDateProp,
}) => {
	const [callDate, setCallDate] = useState<any>('today');

	const callDateHandler = () => {
		if (callDate === 'today') {
			setStartDate(TODAY_CONST);
			return TODAY_CONST.toLocaleDateString();
		} else if (callDate === 'tomorrow') {
			setStartDate(TOMORROW_CONST);
			return TOMORROW_CONST.toLocaleDateString();
		} else {
			return startDate?.toLocaleDateString();
		}
	};

	useEffect(() => {
		callDateHandler();
		setCallDateProp(callDate);
		// setStartDate()
	}, [callDate, startDate]);

	return (
		<div className={styles.planTime}>
			<div className={styles.todayTomorrow}>
				<div
					className={`${styles.tab}
        ${callDate === 'today' && styles.tabActive}`}
					onClick={() => setCallDate('today')}
				>
					Сегодня
				</div>
				<div
					className={`${styles.tab}
        ${callDate === 'tomorrow' && styles.tabActive}`}
					onClick={() => setCallDate('tomorrow')}
				>
					Завтра
				</div>
			</div>
			<div
				className={`${styles.tab} ${styles.datepickerTab}
    ${callDate === 'choice' && styles.tabActive}
    ${callDate === 'choice' && styles.datePickTab}`}
			>
				<DatePicker
					onCalendarOpen={() => setCallDate('choice')}
					placeholderText={'Выбрать дату'}
					className={`${styles.datepicker} 
                     ${callDate === 'choice' && styles.activePicker}`}
					selected={startDate}
					locale={ru}
					dateFormat="dd/MM/yyyy"
					onChange={(date) => setStartDate(date)}
				/>
			</div>
		</div>
	);
};
