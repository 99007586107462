import { MutableRefObject, useCallback, useEffect } from 'react';

export function useOnClickOutside(
	ref: MutableRefObject<any>,
	handler: CallableFunction
) {
	const listener = useCallback(
		(event: MouseEvent | TouchEvent) => {
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}

			handler(event);
		},
		[ref, handler]
	);

	useEffect(() => {
		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);

		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, handler]);
}
