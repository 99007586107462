import { FC, useRef, useState } from 'react';
import { Map, YMaps } from '@pbe/react-yandex-maps';
import {
	YMAPS_API_KEY,
	YMAPS_SUGGEST_API_KEY,
} from '../../../../shared/consts/YMaps';
import styles from './styles.module.scss';
import { useFormContext } from 'react-hook-form';
import { formattedDetailAddressHandler } from '../../../../helpers/formattedDetailAddressHandler';

const behaviors = [''];
const mapOptions = { minZoom: 8, maxZoom: 20 };
const defaultState = {
	center: [55.751574, 37.573856],
	zoom: 11,
	controls: [],
};

interface YMapsAddressProps {
	id: string;
}

export const YMapsAddress: FC<YMapsAddressProps> = ({ id }) => {
	const map = useRef<ymaps.Map>();

	const [isMount, setIsMount] = useState<boolean>(false);

	const { setValue } = useFormContext();

	const loadSuggest = (ymaps: any) => {
		const suggestView =
			!isMount && new ymaps.SuggestView(id, { results: 5, zIndex: 9 });
		setIsMount(true);
		suggestView.events.add('select', (e: any) => {
			const item = e.get('item');
			ymaps.geocode(item.value).then(function (res: any) {
				const geoObj = res.geoObjects.get(0);
				const address =
					geoObj.properties.getAll().metaDataProperty.GeocoderMetaData.Address;
				const addressFieldsObj = formattedDetailAddressHandler(
					address?.Components
				);
				const addressLat = geoObj.geometry._coordinates[0];
				const addressLon = geoObj.geometry._coordinates[1];

				Object.entries(addressFieldsObj).forEach(([key, value]) => {
					setValue(`addressFields.${key}`, value);
				});
				setValue(`addressFields.lon`, addressLon);
				setValue(`addressFields.lat`, addressLat);

				geoObj.properties.balloonContentHeader = address.formatted;
				setValue('address', address.formatted);
				const isHouseSelected = address.Components.find(
					(elem: any) => elem.kind === 'house'
				);
				if (isHouseSelected) {
					suggestView.options.set('provider', 'none');
				} else {
					suggestView.options.set('provider', 'yandex#map');
				}
			});
		});
	};
	return (
		<div className={styles.ymapsSuggestWrapper}>
			<YMaps
				query={{ apikey: YMAPS_API_KEY, suggest_apikey: YMAPS_SUGGEST_API_KEY }}
			>
				<Map
					className={styles.myMap}
					// @ts-ignore
					instanceRef={map}
					defaultState={defaultState}
					options={mapOptions}
					behaviors={behaviors}
					onLoad={(ymaps) => loadSuggest(ymaps)}
					modules={['SuggestView', 'geolocation', 'geocode']}
				></Map>
			</YMaps>
		</div>
	);
};
