import { baseApi } from '../index';
import {
	IRepair,
	IService,
	IServicesResponse,
	IWorkResult,
	IWorkResultsResponse,
} from '../../types/api/content';

export const contentApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		services: builder.query<IService[], void>({
			query: () => '/content/services/all',
			transformResponse: (res: IServicesResponse) => res.data,
			keepUnusedDataFor: 360,
		}),
		workResults: builder.query<IWorkResult[], void>({
			query: () => '/content/work-results/all',
			transformResponse: (res: IWorkResultsResponse) => res.data,
			keepUnusedDataFor: 360,
		}),
		repairBlocks: builder.query<IRepair[], void>({
			query: () => '/content/repair-blocks/all',
			transformResponse: (res: any) => res.data,
			keepUnusedDataFor: 360,
		}),
	}),
});

export const { useServicesQuery, useWorkResultsQuery, useRepairBlocksQuery } =
	contentApi;
