import React, { Dispatch, FC, SetStateAction } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import { createPortal } from 'react-dom';
import { Box, SwipeableDrawer } from '@mui/material';
import styles from './styles.module.scss';
import { Puller } from '../../components/Puller/Puller';
import { Modal } from '../Modal';
import { StyledTextField } from '../styledInputs';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '../../components/UI/Button/Button';
import { ButtonSize } from '../../components/UI/Button/types';
import { NavLink } from 'react-router-dom';
import { useAskQuestionMutation } from '../../api/form/form';
import {
	setIsOpenSuccessModal,
	setResponse,
} from '../../store/slices/successModalSlice';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { selectCities } from '../../store/slices/citySlice';
import { selectServices } from '../../store/slices/servicesSlice';
import { MyInputMask } from '../CallMasterRefactoring/components/MyInputMask/MyInputMask';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { CallMasterRefactoringFormFileds, formSchema } from './formSchema';

type OrderAConsultantModalPropsType = {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const OrderAConsultantModal: FC<OrderAConsultantModalPropsType> = ({
	isOpen,
	setIsOpen,
}) => {
	const dispatch = useAppDispatch();
	const { width } = useWindowSize();

	const currentCity = useAppSelector(selectCities).selectedCity;
	const activeService = useAppSelector(selectServices).activeService;

	const [updateForm] = useAskQuestionMutation();

	const formMethods = useForm<CallMasterRefactoringFormFileds>({
		resolver: zodResolver(formSchema),
	});
	const { register, handleSubmit } = formMethods;

	const onSubmit = async (data: CallMasterRefactoringFormFileds) => {
		const filteredData = {
			...data,
			city: currentCity?.name,
			service: activeService?.title,
		};
		const result = await updateForm(filteredData);
		dispatch(setIsOpenSuccessModal(true));
		dispatch(setResponse(result));
		setIsOpen(false);
	};

	if (width <= 1000) {
		return createPortal(
			<SwipeableDrawer
				anchor="bottom"
				onOpen={() => setIsOpen(true)}
				open={isOpen}
				onClose={() => setIsOpen(false)}
				swipeAreaWidth={56}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
			>
				<Box
					padding="25px 16px 24px 16px "
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					className={styles.wrapperMobile}
				>
					<Box marginTop="13px" marginBottom="35px">
						<Puller />
					</Box>
					<FormProvider {...formMethods}>
						<form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
							<h3 className={styles.title}>Заказать консультацию</h3>
							<div className={styles.form}>
								<StyledTextField
									label="Имя"
									className={styles.input}
									{...register('name')}
									required
								/>
								<MyInputMask name="phone" label="Телефон" />
								<StyledTextField
									label="Ваш вопрос:"
									{...register('question')}
									placeholder="Опишите кратко цель вашего обращения, например: обслужить 8 кондиционеров"
									className={styles.input}
									multiline
									rows={4}
									required
								/>
							</div>
							<div className={styles.buttonWrapper}>
								<Button
									size={ButtonSize.LARGE}
									className={styles.button}
									type="submit"
								>
									Отправить заявку
								</Button>
								<p className={styles.annotation}>
									Нажимая на кнопку отправки, я даю согласие на обработку
									персональных данных и соглашаюсь с условиями{' '}
									<NavLink to={'/privacy-policy'}>
										Политики конфиденциальности
									</NavLink>
								</p>
							</div>
						</form>
					</FormProvider>
				</Box>
			</SwipeableDrawer>,
			document.body
		);
	}
	return (
		<Modal
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			classNameContent={styles.modal}
		>
			<FormProvider {...formMethods}>
				<form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
					<h3 className={styles.title}>Заказать консультацию</h3>
					<div className={styles.form}>
						<StyledTextField
							label="Имя"
							className={styles.input}
							{...register('name')}
							required
						/>
						<MyInputMask name="phone" label="Телефон" />
						<StyledTextField
							label="Ваш вопрос:"
							{...register('question')}
							placeholder="Опишите кратко цель вашего обращения, например: обслужить 8 кондиционеров"
							className={styles.input}
							multiline
							rows={4}
							required
						/>
					</div>
					<div className={styles.buttonWrapper}>
						<Button
							size={ButtonSize.LARGE}
							className={styles.button}
							type="submit"
						>
							Отправить заявку
						</Button>
						<p className={styles.annotation}>
							Нажимая на кнопку отправки, я даю согласие на обработку
							персональных данных и соглашаюсь с условиями{' '}
							<NavLink to={'/privacy-policy'}>
								Политики конфиденциальности
							</NavLink>
						</p>
					</div>
				</form>
			</FormProvider>
		</Modal>
	);
};
