import { FC } from 'react';
import Advantage from './index';
import styles from './Advantage.module.scss';

const Advantages: FC = () => {
	return (
		<div className={styles.advantages}>
			<Advantage image="/advantages/first.webp">
				<span>Гарантия</span>
				<br />
				на работы 1 год
			</Advantage>
			<Advantage image="/advantages/second.webp">
				Выезд мастера
				<br />
				<span>бесплатно</span>
			</Advantage>
			<Advantage image="/advantages/third.webp">
				<span>Круглосуточная</span>
				<br />
				поддержка
			</Advantage>
		</div>
	);
};

export default Advantages;
