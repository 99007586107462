import { ZodType, z } from 'zod';

export type DataTypesFromZod<formSchema extends ZodType<any, any, any>> =
	z.infer<formSchema>;

export const formSchema = z.object({
	name: z.string(),
	phone: z.string(),
	question: z.string().min(3),
	repairName: z.null().optional(),
});

export type CallMasterRefactoringFormFileds = DataTypesFromZod<
	typeof formSchema
>;
