import { LocalizationProvider, TimePickerProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import { StyledTimePicker } from '../../../styledInputs';
import { FC } from 'react';

interface MyStyledTimePickerProps extends TimePickerProps<unknown> {
	className: string;
}

export const MyStyledTimePicker: FC<MyStyledTimePickerProps> = ({
	className,
	...props
}) => {
	//logic setValue from rhf instead of local state
	return (
		<LocalizationProvider
			dateAdapter={AdapterDayjs}
			//@ts-ignore
			adapterLocale={ruRU}
			localeText={
				ruRU.components.MuiLocalizationProvider.defaultProps.localeText
			}
		>
			<StyledTimePicker {...props} />
		</LocalizationProvider>
	);
};

{
	/* <StyledTimePicker
className={styles.picker}
label="Время по:"
minTime={dayjs().set('hour', 9)}
maxTime={dayjs().set('hour', 21)}
ampm={false}
value={timeTo}
onChange={(newValue: any) => setTimeTo(newValue)}
/> */
}

// className={className}
// label="Время с:"
// minTime={dayjs().set('hour', 8).set('minute', 59)}
// maxTime={dayjs().set('hour', 21)}
// ampm={false}
// value={timeFrom}
// onChange={(newValue: any) => setTimeFrom(newValue)}
