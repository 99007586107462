import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './NavPhones.module.scss';
import { useSettingsQuery } from '../../../../../api/static/staticApi';
import { useAppDispatch, useAppSelector } from '../../../../../store/hook';
import { selectDevice } from '../../../../../store/slices/deviceSlice';
import cn from 'classnames';
import { setSettings } from '../../../../../store/slices/settingsSlice';

const NavPhones: FC<{ inModal?: boolean, isShowPhone?: boolean }> = ({ inModal = false, isShowPhone = true }) => {
	const dispatch = useAppDispatch();
	const { isError, isLoading, data: settings } = useSettingsQuery();
	const device = useAppSelector(selectDevice);

	const whatsapp = settings && settings['contacts.item.whatsapp'];
	const telegram = settings && settings['contacts.item.telegram'];

	useEffect(() => {
		dispatch(setSettings(settings));
	}, [isError, isLoading, settings]);

	if (isError) return <p>Произошла ошибка</p>;
	if (isLoading) return <p>Идет загрузка...</p>;
	if (!settings) return null;

	return (
		<div className={cn(styles.navPhones, inModal && styles.inModal)}>
			{whatsapp?.value && (
				<a
					href={`https://wa.me/${whatsapp?.value}`}
					target="_blank"
					rel="noreferrer"
				>
					<img src="/whatsapp-footer-icon.svg" alt="" />
				</a>
			)}
			{telegram?.value && (
				<a
					href={`https://t.me/${telegram?.value}`}
					target="_blank"
					rel="noreferrer"
				>
					<img src="/telegram-footer-icon.svg" alt="" />
				</a>
			)}
			{isShowPhone && (!device.isMobile || inModal) && (
				<div className="">
					<Link
						className={styles.navPhone}
						to={`tel:${settings['contacts.item.phone'].value}`}
					>
						{settings['contacts.item.phone'].value}
					</Link>
				</div>
			)}
		</div>
	);
};

export default NavPhones;
