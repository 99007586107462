import React, { FC, useRef, useState } from 'react';
import Title from '../../UI/Title';
import Subtitle from './Subtitle';
import Advantages from './Advantage/Advantages';
import { Button } from '../../UI/Button/Button';
import { ButtonSize } from '../../UI/Button/types';
import { useAppSelector } from '../../../store/hook';
import { selectServices } from '../../../store/slices/servicesSlice';
import { selectCities } from '../../../store/slices/citySlice';
import styles from './First.module.scss';
import '../../../styles/_ymapStyles.scss';
import PageWrapper from '../../Layout/Wrapper/PageWrapper';
import { ApplicationToMasterModal } from '../../../modals/ApplicationToMasterModal/ApplicationToMasterModal';
import { Box } from '@mui/material';
import ymaps from 'yandex-maps';
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';
import { useWindowSize } from '../../../hooks/useWindowSize';

const behaviors = [''];
const mapOptions = { minZoom: 10, maxZoom: 20 };

const First: FC = () => {
	const map = useRef<ymaps.Map>();
	const { width } = useWindowSize();

	const masters = useAppSelector((state) => state.masters.masters);
	const activeService = useAppSelector(selectServices).activeService;
	const selectedCity = useAppSelector(selectCities).selectedCity;

	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [showMap, setShowMap] = useState<boolean>(false);

	const defaultState = {
		center: [
			selectedCity?.lat ? selectedCity?.lat : 55.75,
			selectedCity?.lon ? selectedCity?.lon : 37.57,
		],
		zoom: 12,
		controls: [],
	};

	return (
		<div>
			<section className={`${styles.first} ${showMap && styles.firstWithMap}`}>
				<PageWrapper className={styles.wrap}>
					<ApplicationToMasterModal
						isOpen={isOpenModal}
						setIsOpen={setIsOpenModal}
					/>
					<Title>
						<h1 className={styles.title_h1}>
							<b>{activeService?.title} любых кондиционеров</b>
							<br /> {selectedCity && selectedCity.header}
						</h1>
					</Title>

					<Subtitle />
					<Advantages />
					<div className={styles.buttonsBlock}>
						<Button
							size={ButtonSize.LARGE}
							onClick={() => setIsOpenModal(true)}
							className={styles.callButton}
						>
							Заказать звонок мастера
						</Button>
						<div
							className={styles.mastersNearButton}
							onClick={() => setShowMap((state) => !state)}
						>
							<img src="/master-near-icon.svg" alt="" />
							<span className={styles.buttonText}>
								МАСТЕРА РЯДОМ С ВАМИ
								<img src="/arrow-right-black.svg" alt="" />
							</span>
						</div>
					</div>
				</PageWrapper>
			</section>
			<Box
				className={`${styles.ymapsWrapper} ${
					showMap && styles.showYmapsWrapper
				}`}
			>
				<section onClick={() => setShowMap(false)} className={styles.svgWrapper}>
					<svg
						className={styles.closeIcon}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 48 48"
						width="40px"
						height="40px"
					>
						<path
							fill="#F44336"
							d="M21.5 4.5H26.501V43.5H21.5z"
							transform="rotate(45.001 24 24)"
						/>
						<path
							fill="#F44336"
							d="M21.5 4.5H26.5V43.501H21.5z"
							transform="rotate(135.008 24 24)"
						/>
					</svg>
                    <p>закрыть</p>
				</section>
				<YMaps>
					<Map
						defaultState={defaultState}
						options={mapOptions}
						behaviors={behaviors}
						//@ts-ignore
						instanceRef={map}
						className={styles.map}
					>
						{masters?.masters?.map((master: any) => (
							<Placemark
								key={master?.id}
								coords
								geometry={[master?.lat, master?.lon]}
								options={{
									iconLayout: 'default#image',
									iconImageHref: '/master-geo.svg',
									iconContentSize: [100, 100],
									iconContentOffset: [10, 10],
								}}
								modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
								properties={{
									balloonContentHeader: master.name,
									balloonContentBody: `
                                        <div>
                                          <b>Оценка:</b> ${master.rate}
                                          <br>
                                          <b>Отзывов:</b> ${master.reviews_number}
                                          <br>
                                          <b>Опыт работы:</b> ${master.experience}
                                        </div>`,
									hintContent: `<div class="yampsHint" >
                                                <div class="yampsHint--border-fragment"></div>   
                                                <div class="yampsHint--region">
                                                    ${master.name}
                                                 </div>
                                                <div class="yampsHint--enter">
                                                    <b>Оценка:</b> ${master.rate}
                                                    <br>
                                                    <b>Отзывов:</b> ${master.reviews_number}
                                                    <br>
                                                    <b>Опыт работы:</b> ${master.experience}
                                                </div>
                                            </div>`,
								}}
							/>
						))}
					</Map>
				</YMaps>
			</Box>
			<PageWrapper>
				{width < 500 && (
					<img
						className={styles.mobileImage}
						src={'/bg-man-mobile.webp'}
						alt=""
					/>
				)}
			</PageWrapper>
		</div>
	);
};

export default First;
