import { createSlice } from '@reduxjs/toolkit';

export interface IRepairsState {
	repairs: any;
}

const initialState: IRepairsState = {
	repairs: [],
};

const repairsBlockSlice = createSlice({
	name: 'repairsBlock',
	initialState,
	reducers: {
		setRepairs(state, action) {
			state.repairs = action.payload;
		},
	},
});

export const { setRepairs } = repairsBlockSlice.actions;
export default repairsBlockSlice;
