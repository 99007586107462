import { onlyNumbers } from '../../helpers/onlyNumbers';
import { baseApi } from '../index';

export const formApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		requestMasterCall: builder.mutation<any, any>({
			query: (body) => ({
				url: '/form/request-master-call',
				method: 'POST',
				body,
			}),
			transformResponse: (res: any) => res.data,
		}),
		callMaster: builder.mutation<any, any>({
			query: (body) => {
				const filteredBody = {
					...body,
					phone: onlyNumbers(body.phone),
				};
				return { url: '/form/call-master', method: 'POST', body: filteredBody };
			},
			transformResponse: (res: any) => res.data,
		}),
		askQuestion: builder.mutation<any, any>({
			query: (body) => ({
				url: '/form/ask-question',
				method: 'POST',
				body,
			}),
			transformResponse: (res: any) => res.data,
		}),
	}),
});

export const {
	useRequestMasterCallMutation,
	useCallMasterMutation,
	useAskQuestionMutation,
} = formApi;
