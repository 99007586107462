import { ButtonMode, ButtonProps, ButtonSize } from './types';
import { FC } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

export const Button: FC<ButtonProps> = ({
	mode = ButtonMode.DEFAULT,
	size = ButtonSize.MEDIUM,
	children,
	className,
	disabled = false,
	onClick = undefined,
	...props
}) => {
	return (
		<>
			<button
				className={cn(
					styles.btn,
					styles[mode],
					styles[size],
					disabled && styles.disabled,
					className && [className]
				)}
				onClick={onClick}
				disabled={disabled}
				{...props}
			>
				{children}
			</button>
		</>
	);
};
