import React, { useRef, useState } from 'react';
import { Box } from '@mui/material';
import styles from './styles.module.scss';
import { useOutsideClickHandler } from '../../hooks/useOutsideClickHandler';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { ICity } from '../../types/api/cities';
import { useNavigate } from 'react-router-dom';
import { setCity } from '../../store/slices/citySlice';

type SelectComponentPropsType = {
	selectedCity: ICity;
};

export const SelectComponent: React.FC<SelectComponentPropsType> = ({
	selectedCity,
}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [isOpenDropdown, setIsOpenDropdown] = useState(false);
	const selectRef = useRef(null);

	const cities = useAppSelector((state) => state.cities.cities);

	const changeCityHandler = (city: ICity) => {
		localStorage.setItem('currentCity', JSON.stringify(city));
		dispatch(setCity(city));
		setIsOpenDropdown(false);
		navigate({ search: `?utm_city=${city.slug}` });
	};

	useOutsideClickHandler(selectRef, setIsOpenDropdown);

	return (
		<Box ref={selectRef}>
			<Box
				className={`${styles.selectedItem} ${
					isOpenDropdown && styles.dropdownOpened
				}`}
				onClick={() => {
					setIsOpenDropdown((state) => !state);
				}}
			>
				{selectedCity.name}{' '}
				<img
					src="/arrow-dropdown.svg"
					style={isOpenDropdown ? { rotate: '180deg' } : {}}
					alt="arrow"
				/>
			</Box>
			{isOpenDropdown && (
				<Box className={styles.unselectedItemsBlock}>
					{cities?.map((city) => (
						<Box
							className={`${styles.unselectedItem} ${
								city.id === selectedCity.id && styles.activeItem
							}`}
							key={city.id}
							onClick={() => changeCityHandler(city)}
						>
							{city.name}
						</Box>
					))}
				</Box>
			)}
		</Box>
	);
};
