import { useEffect } from 'react';

export const useOutsideClickHandler = (
	ref: any,
	onClickOutside: (value: boolean) => void
) => {
	useEffect(() => {
		// Alert if clicked on outside of element
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				onClickOutside(false);
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
};
