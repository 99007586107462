import { FC, useEffect } from 'react';
import styles from './Tag.module.scss';
import { IService } from '../../../../types/api/content';
import {
	selectServices,
	setActiveService,
} from '../../../../store/slices/servicesSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

interface Props {
	service: IService;
	executeSroll: () => void;
	isBottom: boolean
}

const Tag: FC<Props> = ({ service, executeSroll, isBottom }) => {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	const services = useAppSelector((state) => state.services.items);
	const activeService = useAppSelector(selectServices).activeService;
	const cityFromLs = localStorage.getItem('currentCity');
	const cityFromLsParsed =
		cityFromLs && cityFromLs !== 'undefined' && JSON.parse(cityFromLs);

	useEffect(() => {
		const activeServiceByPathname = services?.find(
			(service) => `/${service.slug}` === pathname
		);
		activeServiceByPathname &&
			dispatch(setActiveService(activeServiceByPathname));
	}, [pathname]);

	const handleClick = () => {
		isBottom && executeSroll()
	}

	return (
		<Link
			to={`/${service.slug}?utm_city=${cityFromLsParsed?.slug}`}
			className={cn(
				styles.tag,
				activeService?.id === service.id && styles.active
			)}
			onClick={handleClick}
		>
			{service.name}
		</Link>
	);
};

export default Tag;
