import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './NavList.module.scss';
import City from './City/City';
import { useCitiesQuery } from '../../../../../api/cities/cityApi';
import { useAppDispatch } from '../../../../../store/hook';
import { setCities } from '../../../../../store/slices/citySlice';
import { HashLink as Link } from 'react-router-hash-link';

interface Props {
	showLinks: boolean;
	showLogo?: boolean;
	setIsOpen?: Dispatch<SetStateAction<boolean>>;
}

const NavList: FC<Props> = ({ showLinks, showLogo = true, setIsOpen }) => {
	const dispatch = useAppDispatch();
	const { isError, isLoading, data: cities } = useCitiesQuery();

	useEffect(() => {
		cities && dispatch(setCities(cities));
	}, [isError, isLoading, cities]);

	return (
		<>
			{showLogo && (
				<div className={styles.logo}>
					<NavLink
						to="/"
						onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
					>
						<img src="/logo.svg" alt="logo" />
					</NavLink>
				</div>
			)}
			<City />
			{showLinks && (
				<>
					<div className={styles.links}>
						<div className={styles.link}>
							<Link smooth to="/#service" onClick={() => setIsOpen(false)}>
								<h1>Услуги</h1>
							</Link>
						</div>
						<div className={styles.link}>
							<Link smooth to="/#repair" onClick={() => setIsOpen(false)}>
								<h1>Неисправности</h1>
							</Link>
						</div>
						<div className={styles.link}>
							<Link smooth to="/#about" onClick={() => setIsOpen(false)}>
								<h1 style={{ whiteSpace: 'nowrap' }}>О компании</h1>
							</Link>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default NavList;
