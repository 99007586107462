import { baseApi } from '../index';
import { ICitiesResponse, ICity, ICityResponse } from '../../types/api/cities';

export const cityApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		cities: builder.query<ICity[], void>({
			query: () => '/city/all',
			transformResponse: (res: ICitiesResponse) => res.data,
			keepUnusedDataFor: 360,
		}),
		cityByIp: builder.query<ICity, void>({
			query: () => '/city/by-ip',
			transformResponse: (res: ICityResponse) => res.data,
			keepUnusedDataFor: 360,
		}),
		cityBySlug: builder.query<ICity, string>({
			query: (slug) => `/city/by-slug?slug=${slug}`,
			transformResponse: (res: ICityResponse) => res.data,
			keepUnusedDataFor: 360,
		}),
	}),
});

export const { useCitiesQuery, useCityByIpQuery, useCityBySlugQuery } = cityApi;
