import { FC } from 'react';
import styles from './Second.module.scss';
import Title, { TitleSize } from '../../UI/Title';
import Card from './Card';
import PageWrapper from '../../Layout/Wrapper/PageWrapper';

const Second: FC = () => {
	return (
		<section className={styles.second}>
			<PageWrapper>
				<Title className={styles.title} size={TitleSize.MEDIUM}>
					<h2>
						С чем мы <b>можем вам помочь</b>
					</h2>
				</Title>
				<div className={styles.cards}>
					<Card
						titleRegular={'Ремонт и обслуживание'}
						titleBold={'бытовых кондиционеров с выездом на дом'}
						text={
							'Мастер кондиционеров готов оперативно прибыть к вам на дом для устранения неисправности любой сложности'
						}
						isConsultant={true}
						buttonText={'ВЫЗВАТЬ МАСТЕРА НА ДОМ'}
						image={'/fhelp.webp'}
					/>
					<Card
						titleRegular={'Ремонт и обслуживание'}
						titleBold={'кондиционеров в организациях'}
						text={
							'Работаем с организациями по б/н расчету с НДС. Можем заключить договор на обслуживание на постоянной основе'
						}
						buttonText={'ЗАКАЗАТЬ ЗВОНОК'}
						image={'/shelp.webp'}
					/>
				</div>
			</PageWrapper>
		</section>
	);
};

export default Second;
