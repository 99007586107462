import styles from './City.module.scss';
import React, {FC, useEffect} from 'react';
import {
    useCityByIpQuery,
    useCityBySlugQuery,
} from '../../../../../../api/cities/cityApi';
import {useAppDispatch, useAppSelector} from '../../../../../../store/hook';
import {
    selectCities,
    setCity,
} from '../../../../../../store/slices/citySlice';
import {selectDevice} from '../../../../../../store/slices/deviceSlice';
import {SelectComponent} from '../../../../../SelectComponent/SelectComponent';
import {useLocation} from 'react-router-dom';
import {ICity} from '../../../../../../types/api/cities';

const City: FC = () => {
    const selectedCity = useAppSelector(selectCities).selectedCity;
    const cities = useAppSelector(selectCities).cities;
    const dispatch = useAppDispatch();
    const location = useLocation();
    const device = useAppSelector(selectDevice);
    const {data: city} = useCityByIpQuery();

    const cityBySlug = useCityBySlugQuery(location.search.slice(10))?.data;

    useEffect(() => {
        const cityFromLs = localStorage.getItem('currentCity');
        if (cityFromLs === 'undefined') {
            const findDefaultMoscow = cities.find(city => city.slug === 'moskva')
            dispatch(setCity(findDefaultMoscow))
            localStorage.setItem('currentCity', JSON.stringify(findDefaultMoscow));
            return
        }
        const cityFromLsParsed =
            cityFromLs && JSON.parse(cityFromLs);
        if (!cityFromLs) {
            if (cityFromLsParsed) {
                dispatch(setCity(cityFromLsParsed as ICity));
            } else {
                dispatch(setCity(city as ICity));
                const str = JSON.stringify(city);
                localStorage.setItem('currentCity', str);
            }
        }
    }, [city, cities, localStorage]);

    useEffect(() => {
        const city = JSON.stringify(cityBySlug);
        if (!city) {
            return
        }
        localStorage.setItem('currentCity', city);
        dispatch(setCity(cityBySlug as ICity));
    }, [cityBySlug])

    if (!device.isDesktop) {
        return <></>;
    }

    return (
        <div className={styles.city}>
            <img className={styles.cityMark} src="/city-mark.svg" alt=""/>
            <div style={{whiteSpace: 'nowrap'}}>Ваш город:</div>
            {selectedCity && <SelectComponent selectedCity={selectedCity}/>}
        </div>
    );
};

export default City;
