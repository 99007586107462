import { useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useAppDispatch } from '../store/hook';
import { deviceActions } from '../store/slices/deviceSlice';

const useResizeChecker = () => {
	const dispatch = useAppDispatch();

	const handleWindowSizeChange = debounce(() => {
		dispatch(deviceActions.checkIsMobile());
		dispatch(deviceActions.checkIsTablet());
		dispatch(deviceActions.checkIsDesktop());
	}, 200);

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, [handleWindowSizeChange]);

	return null;
};

export default useResizeChecker;
