import { FC } from 'react';
import styles from './Header.module.scss';
import Navbar from './Navbar/Navbar';
import { useAppSelector } from '../../../store/hook';

const Header: FC = () => {
	const isHeaderOpen = useAppSelector(
		(state) => state.headerState.isHeaderOpen
	);
	return (
		<>
			{isHeaderOpen && <div className={styles.popupForDarkening}></div>}
			<header className={styles.header}>
				<Navbar />
			</header>
		</>
	);
};

export default Header;
