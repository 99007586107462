import React, { FC, useEffect, useRef } from 'react';
import styles from './Third.module.scss';
import Title, { TitleSize } from '../../UI/Title';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import {
	resetServicesBlockFlag,
	selectServices,
} from '../../../store/slices/servicesSlice';
import Service from './Service';
import Tags from './Tag/Tags';
import PageWrapper from '../../Layout/Wrapper/PageWrapper';

const Third: FC = () => {
	const dispatch = useAppDispatch();
	const services = useAppSelector(selectServices);
	const servicesFlag = useAppSelector(
		(state) => state.services.servicesBlockFlag
	);

	const servicesBlockRef = useRef(null);

	// @ts-ignore
	const executeScroll = () =>
		servicesBlockRef.current &&
		servicesBlockRef.current.scrollIntoView({ behavior: 'smooth' });

	useEffect(() => {
		dispatch(resetServicesBlockFlag());
		servicesFlag > 0 && executeScroll();
	}, [servicesFlag]);

	if (!services || !services.items) {
		return <></>;
	}

	return (
		<section className={styles.third} id="service" ref={servicesBlockRef}>
			<PageWrapper>
				<Title className={styles.title} size={TitleSize.MEDIUM}>
					<h1 id="top-title">
						Мы оказываем <b>полный спектр услуг</b>
					</h1>
				</Title>
				<div className={styles.background}></div>
				<Tags executeScroll={executeScroll} services={services.items} />
				<Service />
				<Tags executeScroll={executeScroll} isBottom={true} services={services.items} />
			</PageWrapper>
		</section>
	);
};

export default Third;
