import React, { FC, useState } from 'react';
import styles from './Third.module.scss';
import Title, { TitleSize } from '../../UI/Title';
import { useAppSelector } from '../../../store/hook';
import PageWrapper from '../../Layout/Wrapper/PageWrapper';
import { IRepair } from '../../../types/api/content';
import { MEDIA_URL } from '../../../config/constants';
import { CallMasterRefactoringModal } from '../../../modals/CallMasterRefactoring/CallMasterRefactoringModal';

const Fourth: FC = () => {
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [clickedRepairVariant, setClickedRepairVariant] = useState<IRepair>(
		{} as IRepair
	);
	const repairsBlocks = useAppSelector((state) => state.repairs.repairs);

	return (
		<section className={styles.fourth} id="repair">
			<PageWrapper>
				<CallMasterRefactoringModal
					isOpen={isOpenModal}
					setIsOpen={setIsOpenModal}
					repairVariant={clickedRepairVariant}
				/>
				<Title className={styles.title} size={TitleSize.MEDIUM}>
					<h1>
						Устраним любую <b>поломку кондиционера</b>
					</h1>
				</Title>
				<div className={styles.contentBlock}>
					{repairsBlocks?.map((el: IRepair) => (
						<div className={styles.contentItem} key={el.id}>
							<h5 className={styles.title}>{el.header}</h5>
							<div className={styles.middleBlock}>
								<p className={styles.description}>{el.description}</p>
								<img src={`${MEDIA_URL}${el.image}`} alt="" />
								{el.min_price && (
									<span className={styles.minPrice}>
										Ремонт <b>от {el.min_price} ₽</b>
									</span>
								)}
							</div>
							<button
								className={styles.button}
								onClick={() => {
									setIsOpenModal(true);
									setClickedRepairVariant(el);
								}}
							>
								<span>Вызвать мастера</span>{' '}
								<img src="/arrow-right-blue.svg" alt="" />
							</button>
						</div>
					))}
				</div>
			</PageWrapper>
		</section>
	);
};

export default Fourth;
