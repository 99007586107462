import { FC, ReactNode } from 'react';
import styles from './Advantage.module.scss';

interface Props {
	image: string;
	children: ReactNode;
}

const Advantage: FC<Props> = ({ image, children }) => {
	return (
		<div className={styles.advantage}>
			<div className={styles.image}>
				<img src={image} alt="" />
				<div className={styles.checked}></div>
			</div>
			<div className={styles.text}>{children}</div>
		</div>
	);
};

export default Advantage;
