import { useSettingsQuery } from '../api/static/staticApi'
import parse from 'html-react-parser'

export const useOptions = () => {
    const { isError: isErrorStatic, isLoading: isLoadingStatic, data: staticData} = useSettingsQuery()

	const bodyMetrics = staticData && staticData['body.metrics.scripts']?.value && staticData['body.metrics.scripts']?.value

	const headMetrics = staticData && staticData['head.metrics.counter']?.value && staticData['head.metrics.counter']?.value

    return {headMetrics, bodyMetrics}
}