import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import { createPortal } from 'react-dom';
import { Box, SwipeableDrawer } from '@mui/material';
import styles from './styles.module.scss';
import { Puller } from '../../components/Puller/Puller';
import { Modal } from '../Modal';
import 'react-datepicker/dist/react-datepicker.css';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import {
	setIsOpenSuccessModal,
	setResponse,
} from '../../store/slices/successModalSlice';
import Title from '../../components/UI/Title';

type SuccessModalPropsType = {
	isOpen: boolean;
	setIsOpen?: Dispatch<SetStateAction<boolean>>;
};

export const SuccessModal: FC<SuccessModalPropsType> = ({
	isOpen,
	setIsOpen,
}) => {
	const dispatch = useAppDispatch();
	const { width } = useWindowSize();

	const response = useAppSelector((state) => state.successModal.response);

	const openHandler = useCallback(() => {
		dispatch(setIsOpenSuccessModal(true));
	}, [setIsOpen]);
	const closeHandler = useCallback(() => {
		dispatch(setIsOpenSuccessModal(false));
		dispatch(setResponse({}));
	}, [setIsOpen]);

	if (width <= 1000) {
		return createPortal(
			<SwipeableDrawer
				anchor="bottom"
				onOpen={openHandler}
				open={isOpen}
				onClose={closeHandler}
				swipeAreaWidth={56}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
			>
				<Box
					padding="25px 16px 24px 16px "
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					className={styles.wrapperMobile}
				>
					<Box marginTop="13px" marginBottom="35px">
						<Puller />
					</Box>
					<Box>
						{/*При успешном запросе в data будет null*/}
						<Title className={styles.modalTitle}>
							{response.data === null
								? 'Заявка успешно отправлена'
								: 'Что-то пошло не так'}
						</Title>
						<p className={styles.modalDescription}>
							{/*При успешном запросе в data будет null*/}
							{response.data === null
								? 'Мы очень скоро с вами свяжемся'
								: 'Попробуйте позже'}
						</p>
					</Box>
				</Box>
			</SwipeableDrawer>,
			document.body
		);
	}
	return (
		<Modal
			isOpen={isOpen}
			onClose={closeHandler}
			classNameContent={styles.modal}
		>
			<Box>
				{/*При успешном запросе в data будет null*/}
				<Title className={styles.modalTitle}>
					{response.data === null
						? 'Заявка успешно отправлена'
						: 'Что-то пошло не так'}
				</Title>
				<p className={styles.modalDescription}>
					{/*При успешном запросе в data будет null*/}
					{response.data === null
						? 'Мы очень скоро с вами свяжемся'
						: 'Попробуйте позже'}
				</p>
			</Box>
		</Modal>
	);
};
