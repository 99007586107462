import { FC } from 'react';
import styles from './Right.module.scss';

interface Props {
	image: string;
	plus: any;
}

const Aspect: FC<Props> = ({ image, plus }) => {
	return (
		<div className={styles.aspect}>
			<img src={image} alt="" />
			<h5>{plus.title}</h5>
			<p>{plus.description}</p>
		</div>
	);
};

export default Aspect;
