import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/Layout/Wrapper/PageWrapper';
import styles from './styles.module.scss';
import { useAppSelector } from '../../store/hook';
import axios from 'axios';
import { BASE_URL } from '../../config/constants';

export type TextPageType = {
	body: string;
	id: number;
	slug: string;
	title: string;
};
export const PrivacyPolicy = () => {
	const settings = useAppSelector((state) => state.settings.settings);
	const privacyPolicySetting =
		settings && settings['site.text_pages.privacy_policy'];
	const [privacyPolicyTextPage, setPrivacyPolicyTextPage] =
		useState<TextPageType | null>(null);

	useEffect(() => {
		privacyPolicySetting?.value &&
			axios
				.get(`${BASE_URL}/api/content/text-page/by-slug`, {
					params: {
						slug: privacyPolicySetting?.value,
					},
				})
				.then((res) => setPrivacyPolicyTextPage(res.data.data))
				.catch((err) => console.error(err.message));
	}, [privacyPolicySetting]);

	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, []);

	return (
		<section className={styles.privacyPolicySection}>
			<PageWrapper className={styles.privacyPolicy}>
				<div className={styles.block}>
					<h1 className={styles.title}>{privacyPolicyTextPage?.title}</h1>
					<p
						className={styles.content}
						dangerouslySetInnerHTML={{
							__html: privacyPolicyTextPage?.body
								? privacyPolicyTextPage?.body
								: '',
						}}
					/>
				</div>
			</PageWrapper>
		</section>
	);
};
