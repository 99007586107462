import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import { createPortal } from 'react-dom';
import { Box, SwipeableDrawer } from '@mui/material';
import styles from './styles.module.scss';
import { Puller } from '../../components/Puller/Puller';
import { Modal } from '../Modal';
import { StyledTextField } from '../styledInputs';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import { Button } from '../../components/UI/Button/Button';
import { ButtonSize } from '../../components/UI/Button/types';
import { useRequestMasterCallMutation } from '../../api/form/form';
import { NavLink } from 'react-router-dom';
import {
	setIsOpenSuccessModal,
	setResponse,
} from '../../store/slices/successModalSlice';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { selectCities } from '../../store/slices/citySlice';
import { selectServices } from '../../store/slices/servicesSlice';
import { MyInputMask } from '../CallMasterRefactoring/components/MyInputMask/MyInputMask';
import { MyStyledTimePicker } from '../CallMasterRefactoring/components/MyStyledTimePicker/MyStyledTimePicker';
import { TIME_FROM, TIME_TO } from '../CallMasterRefactoring/consts/dateConsts';
import { FormProvider, useForm } from 'react-hook-form';
import {
	CallMasterRefactoringFormFileds,
	formSchema,
} from '../CallMasterRefactoring/consts/formSchema';
import { zodResolver } from '@hookform/resolvers/zod';

type FieldsType = {
	name: string;
	phone: string;
	date: string;
	timeFrom: string;
	timeTo: string;
	address: string;
};
export const FIELDS_CONST: FieldsType = {
	name: 'Ваше имя',
	phone: 'Телефон',
	date: 'День звонка',
	timeFrom: 'Время с',
	timeTo: 'Время по',
	address: 'Адрес',
};

type ApplicationToMasterModalPropsType = {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const TODAY_CONST = new Date();
export const TOMORROW_CONST = new Date(TODAY_CONST);
TOMORROW_CONST.setDate(TOMORROW_CONST.getDate() + 1);

export const ApplicationToMasterModal: FC<
	ApplicationToMasterModalPropsType
> = ({ isOpen, setIsOpen }) => {
	const currentCity = useAppSelector(selectCities).selectedCity;
	const activeService = useAppSelector(selectServices).activeService;

	const dispatch = useAppDispatch();
	const { width } = useWindowSize();

	const [errorsState, setErrorsState] = useState<Array<any>>([]);

	const [updateForm, { isError, error }] = useRequestMasterCallMutation();

	const formMethods = useForm<CallMasterRefactoringFormFileds>({
		resolver: zodResolver(formSchema),
	});

	const { register, handleSubmit } = formMethods;

	const [callDate, setCallDate] = useState<any>('today');
	const [timeFrom, setTimeFrom] = useState<Dayjs | null>(
		dayjs('2022-04-17T9:00:59')
	);
	const [timeTo, setTimeTo] = useState<Dayjs | null>(dayjs('2022-04-17T21:00'));
	const [startDate, setStartDate] = useState<any | null>(null);

	const fromHours =
		timeFrom && timeFrom?.hour() >= 10
			? timeFrom?.hour()
			: `0${timeFrom?.hour()}`;
	const fromMinutes =
		timeFrom && timeFrom?.minute() >= 10
			? timeFrom?.minute()
			: `0${timeFrom?.minute()}`;
	const toHours =
		timeTo && timeTo?.hour() >= 10 ? timeTo?.hour() : `0${timeTo?.hour()}`;
	const toMinutes =
		timeTo && timeTo?.minute() >= 10
			? timeTo?.minute()
			: `0${timeTo?.minute()}`;

	const callDateHandler = () => {
		if (callDate === 'today') {
			setStartDate(TODAY_CONST);
			return TODAY_CONST.toLocaleDateString();
		} else if (callDate === 'tomorrow') {
			setStartDate(TOMORROW_CONST);
			return TOMORROW_CONST.toLocaleDateString();
		} else {
			return startDate?.toLocaleDateString();
		}
	};

	const onSubmit = async (data: CallMasterRefactoringFormFileds) => {
		const filteredData = {
			...data,
			city: currentCity?.name,
			service: activeService?.title,
			timeFrom: `${fromHours}:${fromMinutes}`,
			timeTo: `${toHours}:${toMinutes}`,
			date: callDateHandler(),
		};
		const result = await updateForm(filteredData);
		dispatch(setIsOpenSuccessModal(true));
		dispatch(setResponse(result));
		setIsOpen(false);
	};

	useEffect(() => {
		// @ts-ignore
		setErrorsState(error?.data?.errors);
	}, [isError]);

	useEffect(() => {
		callDateHandler();
		// setStartDate()
	}, [callDate, startDate]);

	if (width <= 1000) {
		return createPortal(
			<SwipeableDrawer
				anchor="bottom"
				onOpen={() => setIsOpen(true)}
				open={isOpen}
				onClose={() => setIsOpen(false)}
				swipeAreaWidth={56}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
			>
				<Box
					padding="25px 16px 24px 16px "
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					className={styles.wrapperMobile}
				>
					<Box marginTop="13px" marginBottom="35px">
						<Puller />
					</Box>
					<FormProvider {...formMethods}>
						<form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
							<h3 className={styles.title}>
								Оставить заявку на звонок мастера
							</h3>
							<div className={styles.form}>
								<StyledTextField
									label="Имя"
									className={styles.input}
									{...register('name')}
									required
								/>
								<MyInputMask name="phone" label="Телефон" />
							</div>
							<div className={styles.planTime}>
								<h3 className={styles.titlePlanTime}>Когда вам позвонить?</h3>
								<div className={styles.todayTomorrow}>
									<div
										className={`${styles.tab} 
                                         ${
																						callDate === 'today' &&
																						styles.tabActive
																					}`}
										onClick={() => setCallDate('today')}
									>
										Сегодня
									</div>
									<div
										className={`${styles.tab} 
                                         ${
																						callDate === 'tomorrow' &&
																						styles.tabActive
																					}`}
										onClick={() => setCallDate('tomorrow')}
									>
										Завтра
									</div>
								</div>
								{/*<div className={`${styles.tab} */}
								{/*             ${callDate === 'choice' && styles.tabActive} */}
								{/*             ${callDate === 'choice' && styles.datePickTab}`}>*/}
								{/*    <DatePicker*/}
								{/*        onCalendarOpen={() => setCallDate('choice')}*/}
								{/*        placeholderText={'Выбрать дату'}*/}
								{/*        className={`${styles.datepicker} ${callDate === 'choice' && styles.activePicker}`}*/}
								{/*        selected={startDate}*/}
								{/*        locale={ru}*/}
								{/*        dateFormat={'dd.MM.yyyy'}*/}
								{/*        onChange={(date) => setStartDate(date)}*/}
								{/*    />*/}
								{/*</div>*/}
							</div>
							<div className={styles.favoriteTime}>
								<div className={styles.inputsBlock}>
									{
										<LocalizationProvider
											dateAdapter={AdapterDayjs}
											//@ts-ignore
											adapterLocale={ru}
											localeText={
												ruRU.components.MuiLocalizationProvider.defaultProps
													.localeText
											}
										>
											<MyStyledTimePicker
												className={styles.picker}
												label="Время с:"
												minTime={TIME_FROM.min}
												maxTime={TIME_FROM.max}
												ampm={false}
												value={timeFrom}
												onChange={(newValue: any) => setTimeFrom(newValue)}
											/>
											<MyStyledTimePicker
												className={styles.picker}
												label="Время по:"
												minTime={TIME_TO.min}
												maxTime={TIME_TO.max}
												ampm={false}
												value={timeTo}
												onChange={(newValue: any) => setTimeTo(newValue)}
											/>
										</LocalizationProvider>
									}
								</div>
							</div>
							<div className={styles.buttonWrapper}>
								<Button
									size={ButtonSize.LARGE}
									className={styles.button}
									type="submit"
									onClick={() => ym(96413801,'reachGoal','newlead')}
								>
									Отправить заявку
								</Button>
								{errorsState?.length && (
									<div className={styles.errorsBlock}>
										{errorsState?.map((error, i) => (
											<div key={i}>
												{/*@ts-ignore*/}
												<span>"{FIELDS_CONST[error.attribute]}" - </span>
												<span>{error.message}</span>
											</div>
										))}
									</div>
								)}
								<p className={styles.annotation}>
									Нажимая на кнопку отправки, я даю согласие на обработку
									персональных данных и соглашаюсь с условиями{' '}
									<NavLink to={'/privacy-policy'}>
										Политики конфиденциальности
									</NavLink>
								</p>
							</div>
						</form>
					</FormProvider>
				</Box>
			</SwipeableDrawer>,
			document.body
		);
	}
	return (
		<Modal
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			classNameContent={styles.modal}
		>
			<FormProvider {...formMethods}>
				<form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
					<h3 className={styles.title}>Оставить заявку на звонок мастера</h3>
					<div className={styles.form}>
						<StyledTextField
							label="Имя"
							className={styles.input}
							{...register('name')}
							required
						/>
						<MyInputMask name="phone" label="Телефон" />
					</div>
					<div className={styles.planTime}>
						<h3 className={styles.titlePlanTime}>Когда вам позвонить?</h3>
						<div className={styles.todayTomorrow}>
							<div
								className={`${styles.tab} 
                                         ${
																						callDate === 'today' &&
																						styles.tabActive
																					}`}
								onClick={() => setCallDate('today')}
							>
								Сегодня
							</div>
							<div
								className={`${styles.tab} 
                                         ${
																						callDate === 'tomorrow' &&
																						styles.tabActive
																					}`}
								onClick={() => setCallDate('tomorrow')}
							>
								Завтра
							</div>
						</div>
						{/*<div className={`${styles.tab} */}
						{/*                     ${callDate === 'choice' && styles.tabActive} */}
						{/*                     ${callDate === 'choice' && styles.datePickTab}`}>*/}
						{/*    <DatePicker*/}
						{/*        onCalendarOpen={() => setCallDate('choice')}*/}
						{/*        placeholderText={'Выбрать дату'}*/}
						{/*        className={`${styles.datepicker} ${callDate === 'choice' && styles.activePicker}`}*/}
						{/*        selected={startDate}*/}
						{/*        locale={ru}*/}
						{/*        // onSelect={handleDateSelect} //when day is clicked*/}
						{/*        onChange={(date) => setStartDate(date)}*/}
						{/*    />*/}
						{/*</div>*/}
					</div>
					<div className={styles.favoriteTime}>
						<div className={styles.inputsBlock}>
							{
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
									//@ts-ignore
									adapterLocale={ru}
									localeText={
										ruRU.components.MuiLocalizationProvider.defaultProps
											.localeText
									}
								>
									<MyStyledTimePicker
										className={styles.picker}
										label="Время с:"
										minTime={TIME_FROM.min}
										maxTime={TIME_FROM.max}
										ampm={false}
										value={timeFrom}
										onChange={(newValue: any) => setTimeFrom(newValue)}
									/>
									<MyStyledTimePicker
										className={styles.picker}
										label="Время по:"
										minTime={TIME_TO.min}
										maxTime={TIME_TO.max}
										ampm={false}
										value={timeTo}
										onChange={(newValue: any) => setTimeTo(newValue)}
									/>
								</LocalizationProvider>
							}
						</div>
					</div>
					<div className={styles.buttonWrapper}>
						<Button
							size={ButtonSize.LARGE}
							className={styles.button}
							type="submit"
						>
							Отправить заявку
						</Button>
						{errorsState?.length && (
							<div className={styles.errorsBlock}>
								{errorsState?.map((error, i) => (
									<div key={i}>
										{/*@ts-ignore*/}
										<span>"{FIELDS_CONST[error.attribute]}" - </span>
										<span>{error.message}</span>
									</div>
								))}
							</div>
						)}
						<p className={styles.annotation}>
							Нажимая на кнопку отправки, я даю согласие на обработку
							персональных данных и соглашаюсь с условиями{' '}
							<NavLink to={'/privacy-policy'}>
								Политики конфиденциальности
							</NavLink>
						</p>
					</div>
				</form>
			</FormProvider>
		</Modal>
	);
};
