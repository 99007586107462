import React, { FC, useCallback, useState } from 'react';
import { useAppSelector } from '../../../../store/hook';
import { selectServices } from '../../../../store/slices/servicesSlice';
import Title, { TitleSize } from '../../../UI/Title';
import { MEDIA_URL } from '../../../../config/constants';
import styles from './Service.module.scss';
import ContentLoader from 'react-content-loader';
import { ButtonSize } from '../../../UI/Button/types';
import { Button } from '../../../UI/Button/Button';
import { ApplicationToMasterModal } from '../../../../modals/ApplicationToMasterModal/ApplicationToMasterModal';

const Service: FC = () => {
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const handleImageLoaded = useCallback(() => setIsImageLoaded(true), []);
	const activeService = useAppSelector(selectServices).activeService;
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

	if (!activeService) {
		return <></>;
	}
	return (
		<article className={styles.service}>
			<div className={styles.contentBlock}>
				<ApplicationToMasterModal
					isOpen={isOpenModal}
					setIsOpen={setIsOpenModal}
				/>
				<div className={styles.left}>
					<Title
						size={TitleSize.SMALL}
						hasLight={false}
						className={styles.title}
					>
						<h1>
							<b>
								{activeService.title}{' '}
								{
									//@ts-ignore
									activeService?.prices?.min
										? //@ts-ignore
										  `кондиционеров от ${activeService?.prices?.min} ₽`
										: null
								}
							</b>
						</h1>
					</Title>
					<div className={styles.image}>
						{(!activeService.image || !isImageLoaded) && (
							<ContentLoader
								viewBox="0 0 500 500"
								height={390}
								width={360}
								backgroundColor="#f3f3f3"
								foregroundColor="#ecebeb"
							>
								<path d="M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z" />
								<ellipse cx="120" cy="140" rx="28" ry="28" />
							</ContentLoader>
						)}
						<img
							src={MEDIA_URL + activeService.image}
							className={`${
								isImageLoaded ? styles.showImage : styles.opacityZero
							}`}
							alt=""
							loading="lazy"
							onLoad={handleImageLoaded}
						/>
					</div>
				</div>
				<div>
					<h1 className={styles.header}>
						<b>Об услуге:</b>
					</h1>

					<div
						className={styles.text}
						dangerouslySetInnerHTML={{ __html: activeService.about }}
					></div>
				</div>

				{activeService?.works?.length ? (
					<div className={styles.works}>
						<h1 className={styles.header}>
							<b>Что входит в состав работ:</b>
						</h1>

						<ul>
							{activeService.works.map(
								(work) =>
									work.description && <li key={work.id}>{work.description}</li>
							)}
						</ul>
					</div>
				) : (
					''
				)}
			</div>
			{activeService?.prices && (
				<div className={styles.priceList}>
					<h4 className={styles.priceTitle}>
						<b>Прайс-лист</b> на {activeService.price_list_title} кондиционеров
					</h4>
					<div className={styles.pricesBlock}>
						{
							// @ts-ignore
							activeService?.prices?.list.map((priceItem) => (
								<div key={priceItem.uuid} className={styles.priceItem}>
									<span className={styles.priceItemName}>{priceItem.name}</span>
									<div className={styles.productItemsWrapper}>
										{priceItem.products.map((product: any) => (
											<div
												key={product.uuid}
												className={styles.productItemWrapper}
											>
												<span className={styles.productItem}>
													{product.name}
												</span>
												<span className={styles.productPrice}>
													{product.price} ₽
												</span>
											</div>
										))}
									</div>
								</div>
							))
						}
					</div>
				</div>
			)}
			<div className={styles.callButtonWrap}>
				<Button
					size={ButtonSize.LARGE}
					onClick={() => setIsOpenModal(true)}
					className={styles.callButton}
				>
					{activeService.button_text}
				</Button>
			</div>
		</article>
	);
};

export default Service;
