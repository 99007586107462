import { Dayjs } from 'dayjs';

export const getTimes = (timeFrom: Dayjs, timeTo: Dayjs) => {
	const fromHours =
		timeFrom && timeFrom?.hour() >= 10
			? timeFrom?.hour()
			: `0${timeFrom?.hour()}`;
	const fromMinutes =
		timeFrom && timeFrom?.minute() >= 10
			? timeFrom?.minute()
			: `0${timeFrom?.minute()}`;
	const toHours =
		timeTo && timeTo?.hour() >= 10 ? timeTo?.hour() : `0${timeTo?.hour()}`;
	const toMinutes =
		timeTo && timeTo?.minute() >= 10
			? timeTo?.minute()
			: `0${timeTo?.minute()}`;

	return { fromHours, fromMinutes, toHours, toMinutes };
};
