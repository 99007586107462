import { FC } from 'react';
import InputMask from 'react-input-mask';
import { useFormContext } from 'react-hook-form';
import { StyledTextField } from '../../../styledInputs';
import cn from 'classnames';
import styles from './styles.module.scss';

interface MyInputMaskProps {
	className?: string;
	name: string;
	label: string;
}

export const MyInputMask: FC<MyInputMaskProps> = ({
	className,
	name,
	label,
}) => {
	const { register } = useFormContext();
	return (
		<InputMask mask="+7 (999) 999-99-99" {...register(name)}>
			{/*@ts-ignore*/}
			{(inputProps: any) => (
				<StyledTextField
					label={label}
					required
					className={cn(styles.input, {}, [className])}
					{...inputProps}
					InputLabelProps={{
						style: { color: '#CCC0AC' },
					}}
				/>
			)}
		</InputMask>
	);
};
