import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import { createPortal } from 'react-dom';
import { Box, SwipeableDrawer } from '@mui/material';
import styles from './styles.module.scss';
import { Puller } from '../../components/Puller/Puller';
import { Modal } from '../Modal';
import 'react-datepicker/dist/react-datepicker.css';
import CallMasterForm from './CallMasterRefactoring';
import { IRepair } from '../../types/api/content';

type CallMasterModalPropsType = {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	repairVariant?: IRepair;
};

export const CallMasterRefactoringModal: FC<CallMasterModalPropsType> = ({
	isOpen,
	setIsOpen,
	repairVariant,
}) => {
	const { width } = useWindowSize();
	const openHandler = useCallback(() => {
		setIsOpen(true);
	}, [setIsOpen]);
	const closeHandler = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	if (width <= 1000) {
		return createPortal(
			<SwipeableDrawer
				anchor="bottom"
				onOpen={openHandler}
				open={isOpen}
				onClose={closeHandler}
				swipeAreaWidth={56}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
			>
				<Box
					padding="25px 16px 24px 16px "
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					className={styles.wrapperMobile}
				>
					<Box marginTop="13px" marginBottom="35px">
						<Puller />
					</Box>
					<CallMasterForm setIsOpen={setIsOpen} repairVariant={repairVariant} />
				</Box>
			</SwipeableDrawer>,
			document.body
		);
	}
	return (
		<Modal
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			classNameContent={styles.modal}
		>
			<CallMasterForm setIsOpen={setIsOpen} repairVariant={repairVariant} />
		</Modal>
	);
};
