import { baseApi } from '../index';

export const mastersApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		masters: builder.query<any, void>({
			query: () => '/masters/info',
			transformResponse: (res: any) => res.data,
			keepUnusedDataFor: 360,
		}),
	}),
});

export const { useMastersQuery } = mastersApi;
