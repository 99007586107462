import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';

export enum ButtonMode {
	DEFAULT = 'active',
	ACTIVE = 'active',
	INACTIVE = 'inactive',
}

export enum ButtonSize {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
}

export interface ButtonProps
	extends DetailedHTMLProps<
		ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	mode?: ButtonMode;
	size?: ButtonSize;
	className?: string;
	children: ReactNode;
	disabled?: boolean;
	onClick?: (...args: any) => any;
}
