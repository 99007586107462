import { MOBILE, DESKTOP } from '../../config/constants';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type IsMobileType = {
	isMobile: boolean;
	isTablet: boolean;
};

const mobileInitialState = {
	isMobile: window.innerWidth < MOBILE,
	isTablet: window.innerWidth < DESKTOP && window.innerWidth > MOBILE,
	isDesktop: window.innerWidth >= DESKTOP,
};

const deviceSlice = createSlice({
	name: 'device',
	initialState: mobileInitialState,
	reducers: {
		checkIsMobile(state) {
			state.isMobile = window.innerWidth < MOBILE;
		},
		checkIsDesktop(state) {
			state.isDesktop = window.innerWidth >= DESKTOP;
		},
		checkIsTablet(state) {
			state.isTablet =
				window.innerWidth < DESKTOP && window.innerWidth > MOBILE;
		},
	},
});

export const deviceActions = deviceSlice.actions;

export default deviceSlice;

export const selectDevice = (state: RootState) => state.device;
