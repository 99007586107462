import { createBrowserRouter } from 'react-router-dom';

import RootLayout from '../pages/RootLayout';
import { Home } from '../pages/Home';
import { PrivacyPolicy } from '../pages/PrivacyPolicy/PrivacyPolicy';
import { ErrorPage } from '../pages/ErrorPage/ErrorPage';

export const router = createBrowserRouter([
	{
		path: '/',
		element: <RootLayout />,
		children: [
			{
				index: false,
				errorElement: <ErrorPage />,
				children: [
					{
						index: true,
						element: <Home />,
					},
					{
						path: '/:slug',
						element: <Home />,
					},
					{
						path: '/privacy-policy',
						element: <PrivacyPolicy />,
					},
					{
						path: '*',
						element: <ErrorPage />,
					},
				],
			},
		],
	},
	// {
	//     path: '/',
	//     element: <RootLayout/>,
	//     // errorElement: <ErrorPage />,
	//     children: [
	//         {
	//             index: true,
	//             element: <Home/>,
	//         },
	//     ],
	// },
	// {
	//     path: '/:slug',
	//     element: <RootLayout/>,
	//     // errorElement: <ErrorPage />,
	//     children: [
	//         {
	//             index: true,
	//             element: <Home/>,
	//         },
	//     ],
	// },
	// {
	//     path: '/privacy-policy',
	//     element: <RootLayout/>,
	//     // errorElement: <ErrorPage />,
	//     children: [
	//         {
	//             index: true,
	//             element: <PrivacyPolicy/>,
	//         },
	//     ],
	// },
	// {
	//     path: '*',
	//     element: <RootLayout/>,
	//     errorElement: <ErrorPage />,
	//     children: [
	//         {
	//             index: true,
	//             element: <ErrorPage/>,
	//         },
	//     ],
	// },
	// // {
	// //     path: '/site-rules',
	// //     element: <RootLayout/>,
	// //     // errorElement: <ErrorPage />,
	// //     children: [
	// //         {
	// //             index: true,
	// //             element: <SiteRules/>,
	// //         },
	// //     ],
	// // },
]);
