import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface headerOpenState {
	isHeaderOpen: boolean;
}

const initialState: headerOpenState = {
	isHeaderOpen: false,
};

const headerOpenSlice = createSlice({
	name: 'headerSLice',
	initialState,
	reducers: {
		setIsHeaderOpen(state, action) {
			state.isHeaderOpen = action.payload;
		},
	},
});

export const { setIsHeaderOpen } = headerOpenSlice.actions;
export default headerOpenSlice;

export const selectSettings = (state: RootState) => state.masters;
