import { forwardRef, ReactNode } from 'react';
import { animated, Transition } from 'react-spring';
import styles from './BurgerMenu.module.scss';

interface Props {
	isOpen: boolean;
	children: ReactNode;
}

const BurgerMenu = forwardRef<HTMLDivElement | null, Props>(function Modal(
	{ isOpen, children },
	ref
) {
	return (
		<Transition
			items={isOpen}
			from={{ transform: 'translateY(-200%)' }}
			to={{ transform: 'translateY(0%)' }}
			leave={{ transform: 'translateY(-200%)' }}
			enter={{ transform: 'translateY(0%)' }}
		>
			{(style, item) =>
				item && (
					<animated.div style={style} className={styles.modal} ref={ref}>
						{children}
					</animated.div>
				)
			}
		</Transition>
	);
});

export default BurgerMenu;
