import React, { FC, useEffect, useState } from 'react';
import PageWrapper from '../../Layout/Wrapper/PageWrapper';
import Title, { TitleSize } from '../../UI/Title';
import styles from './styles.module.scss';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useMastersQuery } from '../../../api/masters/masters';
import { MEDIA_URL } from '../../../config/constants';
import { useAppDispatch } from '../../../store/hook';
import { setMasters } from '../../../store/slices/mastersSlice';

const Sixth: FC = () => {
	const dispatch = useAppDispatch();

	const { width } = useWindowSize();
	const { data: masters } = useMastersQuery();
	const [activeMaster, setActiveMaster] = useState<any>();
	const numbers = masters?.numbers;
	const text = masters?.text;
	const utp = masters?.utp;

	useEffect(() => {
		setActiveMaster(masters?.masters?.[0]);
		dispatch(setMasters(masters));
	}, [masters]);

	return (
		<section className={styles.sixthSection}>
			<PageWrapper className={styles.sixth}>
				<div className={styles.contentContainer}>
					<div className={styles.leftBlock}>
						<Title className={styles.title} size={TitleSize.MEDIUM}>
							<h1>
								<b>Профессиональные кадры - наше основное кредо</b>
							</h1>
						</Title>
						<p className={styles.description}>{text}</p>
						<div className={styles.achievements}>
							{utp?.map((achiev: any) => (
								<div key={achiev.id} className={styles.achievItem}>
									{achiev?.image && (
										<img src={`${MEDIA_URL}${achiev?.image}`} alt="" />
									)}
									<span dangerouslySetInnerHTML={{ __html: achiev.text }} />
								</div>
							))}
						</div>
					</div>
					{width > 900 && (
						<div className={styles.masterInfoBlock}>
							<div className={styles.mediumBlock}>
								<img
									src={
										activeMaster?.photo
											? `${MEDIA_URL}${activeMaster?.photo}`
											: '/unknown-person.webp'
									}
									alt=""
								/>
								<div className={styles.masterInfo}>
									<div className={styles.name}>{activeMaster?.name}</div>
									<div className={styles.reviews}>
										<div className={styles.starsBlock}>
											{[1, 2, 3, 4, 5].map((rate) => (
												<img
													key={rate}
													src={
														rate <= activeMaster?.rate
															? '/rating-star-icon.svg'
															: '/rating-star-icon-disabled.svg'
													}
													alt=""
												/>
											))}
										</div>
										<div className={styles.reviewsText}>
											{activeMaster?.reviews_number} отзывов
										</div>
									</div>
									<div className={styles.experience}>
										Опыт работы {activeMaster?.experience}
									</div>
								</div>
							</div>
							<div className={styles.rightBlock}>
								{numbers?.map((number: any) => (
									<div key={number.id} className={styles.statItem}>
										<span className={styles.stat}>{number.title}</span>
										<span className={styles.description}>
											{number.description}
										</span>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
				{width > 900 && (
					<div className={styles.swiperContainer}>
						<Swiper
							slidesPerView={4}
							slidesPerGroup={4}
							breakpoints={{
								1200: {
									slidesPerView: 5,
									slidesPerGroup: 5,
								},
							}}
							spaceBetween={20}
							navigation
							pagination={{ clickable: true }}
							modules={[Pagination, Navigation]}
							className={styles.mySwiper}
						>
							{masters?.masters?.length &&
								masters?.masters?.map((master: any) => (
									<SwiperSlide
										key={master.id}
										className={styles.swiperSlide}
										onClick={() => setActiveMaster(master)}
									>
										<div
											className={`${styles.imageWrapper} 
                                                 ${
																										activeMaster?.id ===
																											master.id &&
																										styles.activeImage
																									}`}
										>
											<img
												src={
													master?.photo
														? `${MEDIA_URL}${master?.photo}`
														: '/unknown-person.webp'
												}
												alt=""
											/>
										</div>
										<span className={styles.name}>{master.name}</span>
										<div className={styles.reviews}>
											<div className={styles.starsBlock}>
												{[1, 2, 3, 4, 5].map((rate) => (
													<img
														key={rate}
														src={
															rate <= master.rate
																? '/rating-star-icon.svg'
																: '/rating-star-icon-disabled.svg'
														}
														alt=""
													/>
												))}
											</div>
											<Link
												to={master.reviewsLink}
												className={styles.reviewsText}
											>
												{master.reviews_number} отзывов
											</Link>
										</div>
									</SwiperSlide>
								))}
						</Swiper>
					</div>
				)}
			</PageWrapper>
		</section>
	);
};

export default Sixth;
