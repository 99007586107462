import React, { Dispatch, SetStateAction } from 'react';
import {
	Box,
	FormControl,
	InputLabel,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import styles from './styles.module.scss';
import usePreventScrollOnFocus from '../../hooks/usePreventScrollOnFocus';

type CustomSelectPropsType = {
	label?: string;
	value: string;
	onChange: Dispatch<SetStateAction<any>>;
	className?: string;
	children: any;
	defaultValue?: string;
	placeholder?: string;
	required?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
};

export const CustomSelect: React.FC<CustomSelectPropsType> = ({
	label,
	className,
	children,
	value,
	onChange,
	defaultValue,
	placeholder,
	required = false,
	...props
}) => {
	const preventScrollOnFocus = usePreventScrollOnFocus();
	const handleChange = (event: SelectChangeEvent) => {
		onChange(event.target.value as string);
	};
	if (!document) {
		return null;
	}
	return (
		<Box>
			<FormControl fullWidth required={required}>
				{label && <InputLabel id="region-select-label">{label}</InputLabel>}
				<Select
					placeholder={placeholder}
					className={`${className} ${styles.customSelect}`}
					labelId="region-select-label"
					defaultValue={defaultValue}
					value={value}
					label={label}
					onChange={handleChange}
					required
					{...props}
					onFocus={preventScrollOnFocus}
					sx={{ '& label': { zIndex: '0 !important' } }}
				>
					{children}
				</Select>
			</FormControl>
		</Box>
	);
};
