const stringToHTML = function (str: string) {
	const range = document.createRange();
	const fragment = range.createContextualFragment(str);
	return fragment;
}

export class Parser {
    private static instance: Parser

    static htmlParser(headMetrics?: any){
        if (!Parser.instance){
            if (headMetrics){ 
                Parser.instance = new Parser()
                const metricsDoc = stringToHTML(headMetrics)
                const mockDiv = document.createElement('div')
                mockDiv.appendChild(metricsDoc)
                document.querySelector('head').appendChild(mockDiv)
            }
        }
    }   
}