import { Autocomplete, styled, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';

export const StyledTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: '#347DEE !important',
	},
	'& label.Mui-error': {
		color: '#F56E6E !important',
	},
	'& label': {
		color: '#AFAFAF !important',
	},
	'& .MuiInput-underline:after': {
		// borderColor: 'green'
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			border: '1px solid #E8E8E8 !important',
			// borderColor: '#E8E8E8'
		},
		'&:hover fieldset': {
			border: '1px solid #E8E8E8 !important',
			boxShadow: '1px 1px 5px #E8E8E8',
			transitionDuration: '200ms',
			// borderColor: '#E8E8E8'
		},
		'&.Mui-focused fieldset': {
			border: '1px solid #347DEE !important',
			boxShadow: 'unset',
			// borderColor: '#E8E8E8'
		},
		'&.Mui-error fieldset': {
			borderColor: '#F56E6E !important',
		},
		'&.Mui-error label': {
			color: 'red !important',
		},
	},
});

export const StyledAutoComplete = styled(Autocomplete)({
	'& label.Mui-focused': {
		color: '#347DEE !important',
	},
	'& label.Mui-error': {
		color: '#F56E6E !important',
	},
	'& label': {
		color: '#AFAFAF !important',
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			border: '1px solid #E8E8E8 !important',
			// borderColor: '#E8E8E8'
		},
		'&:hover fieldset': {
			border: '1px solid #E8E8E8 !important',
			boxShadow: '1px 1px 5px #E8E8E8',
			transitionDuration: '200ms',
			// borderColor: '#E8E8E8'
		},
		'&.Mui-focused fieldset': {
			border: '1px solid #347DEE !important',
			boxShadow: 'unset',
			// borderColor: '#E8E8E8'
		},
		'&.Mui-error fieldset': {
			borderColor: '#F56E6E !important',
		},
		'&.Mui-error label': {
			color: 'red !important',
		},
	},
});
export const StyledTimePicker = styled(TimePicker)({
	'& label.Mui-focused': {
		color: '#347DEE !important',
	},
	'& label.Mui-error': {
		color: '#F56E6E !important',
	},
	'& label': {
		color: '#AFAFAF !important',
		zIndex: 0,
	},
	'& .MuiPickersLayout-root': {
		'*': {
			color: 'red !important',
			'& li': { backgroundColor: '#000000 !important' },
		},
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			border: '1px solid #E8E8E8 !important',
			// borderColor: '#E8E8E8'
		},
		'&:hover fieldset': {
			border: '1px solid #E8E8E8 !important',
			boxShadow: '1px 1px 5px #E8E8E8',
			transitionDuration: '200ms',
			// borderColor: '#E8E8E8'
		},
		'&.Mui-focused fieldset': {
			border: '1px solid #347DEE !important',
			boxShadow: 'unset',
			// borderColor: '#E8E8E8'
		},
		'&.Mui-error fieldset': {
			borderColor: '#F56E6E !important',
		},
		'&.Mui-error label': {
			color: 'red !important',
		},
	},
});
