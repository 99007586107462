import { FC, ReactNode } from 'react';
import styles from './Title.module.scss';
import cn from 'classnames';

export enum TitleSize {
	BIG = 'big',
	MEDIUM = 'medium',
	SMALL = 'small',
}

interface Props {
	children: ReactNode;
	className?: string;
	hasLight?: boolean;
	size?: TitleSize;
}

const Title: FC<Props> = ({
	children,
	className = '',
	hasLight = true,
	size = TitleSize.BIG,
}) => {
	return (
		<div
			className={cn(
				styles.title,
				className,
				hasLight && styles.lighted,
				styles[size]
			)}
		>
			{children}
		</div>
	);
};

export default Title;
