import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface mastersState {
	masters: any;
}

const initialState: mastersState = {
	masters: [],
};

const mastersSlice = createSlice({
	name: 'masters',
	initialState,
	reducers: {
		setMasters(state, action) {
			state.masters = action.payload;
		},
	},
});

export const { setMasters } = mastersSlice.actions;
export default mastersSlice;

export const selectSettings = (state: RootState) => state.masters;
