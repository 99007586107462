import { createSlice } from '@reduxjs/toolkit';
import { Settings } from '../../types/api/settings';
import { RootState } from '../store';

interface ISettingsState {
	data: Partial<Settings>
}

const initialState: ISettingsState = {
	data: {}
}

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setSettings(state, action) {
			state.data = action.payload;
		},
	},
});

export const { setSettings } = settingsSlice.actions;
export default settingsSlice;

export const selectSettings = (state: RootState) => state.settings;
