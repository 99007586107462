import React, { FC } from 'react';
import First from '../../components/Blocks/First';
import Second from '../../components/Blocks/Second';
import Third from '../../components/Blocks/Third';
import Fifth from '../../components/Blocks/Fifth';
import Fourth from '../../components/Blocks/Fourth';
import Sixth from '../../components/Blocks/Sixth';
import Seventh from '../../components/Blocks/Seven';
import Eighth from '../../components/Blocks/Eighth';

export const Home: FC = () => {
	return (
		<>
			<First />
			<Second />
			<Third />
			<Fourth />
			<Fifth />
			<Sixth />
			<Seventh />
			<Eighth />
		</>
	);
};

export default Home;
