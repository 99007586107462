import React, { FC, memo, useState } from 'react';
import Title, { TitleSize } from '../../../UI/Title';
import { Button } from '../../../UI/Button/Button';
import styles from './Card.module.scss';
import { ButtonSize } from '../../../UI/Button/types';
import { OrderAConsultantModal } from '../../../../modals/OrderAConsultantModal/OrderAConsultantModal';
import { SuccessModal } from '../../../../modals/SuccessModal/SuccessModal';
import { useAppSelector } from '../../../../store/hook';
import { CallMasterRefactoringModal } from '../../../../modals/CallMasterRefactoring/CallMasterRefactoringModal';

interface Props {
	titleRegular: string;
	titleBold?: string;
	text: string;
	buttonText: string;
	image: string;
	isConsultant?: boolean | null;
}

const Card: FC<Props> = ({
	titleRegular,
	titleBold,
	text,
	buttonText,
	image,
	isConsultant = false,
}) => {
	const isOpenSuccessModal = useAppSelector(
		(state) => state.successModal.isOpenSuccessModal
	);
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [isOpenConsultantModal, setIsOpenConsultantModal] =
		useState<boolean>(false);
	return (
		<div className={styles.wrapper}>
			<OrderAConsultantModal
				isOpen={isOpenConsultantModal}
				setIsOpen={setIsOpenConsultantModal}
			/>
			<CallMasterRefactoringModal
				isOpen={isOpenModal}
				setIsOpen={setIsOpenModal}
			/>
			{/*<CallMasterModal isOpen={isOpenModal} setIsOpen={setIsOpenModal}/>*/}
			<SuccessModal isOpen={isOpenSuccessModal} />
			<div className={styles.card}>
				<div className={styles.container}>
					<Title className={styles.title} size={TitleSize.SMALL}>
						{titleRegular} {titleBold && <b>{titleBold}</b>}
					</Title>
					<p className={styles.text}>{text}</p>
					<Button
						size={ButtonSize.MEDIUM}
						className={styles.button}
						onClick={() => {
							!isConsultant
								? setIsOpenConsultantModal(true)
								: setIsOpenModal(true);
						}}
					>
						{buttonText}
					</Button>
				</div>
			</div>
			<div className={styles.background}>
				<img src={image} alt="" />
			</div>
		</div>
	);
};

export default memo(Card);
